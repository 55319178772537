import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import KeyIcon from '@mui/icons-material/Key';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';
import { color } from '../../Styles/Color';

export const CreateKeyModal = ({ handleClose, isOpen, data }) => {

  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(data.client_secret)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
  }

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose} PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <KeyIcon width="20px" height="20px" sx={{ color: color.primary }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Access Keys</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "30vh",
        width: "60vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        <Stack spacing={2} p={2}>
          <Stack direction="row" gap={2}>
            <Typography fontSize="15px" width="25%">Access Key ID</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography fontSize="15px">{data.client_id || ""}</Typography>
          </Stack>
          <Stack direction="row" gap={2}>
            <Typography fontSize="15px" width="25%">Access Key Secret</Typography>
            <Divider orientation="vertical" flexItem />
            <Stack direction="row" gap={1}>
              <Typography fontSize="15px">{data.client_secret || ""}</Typography>
              <Tooltip title={isCopied ? "Copied" : "Copy"}>
                <ContentCopyIcon
                  sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                  onClick={handleCopyClick}
                />
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}
