import { useAuth0 } from "@auth0/auth0-react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography } from "@mui/material";
import Button from '@mui/material/Button';
import React from "react";
import { color } from "../../Styles/Color";

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return <>
        <Button
            onClick={() => loginWithRedirect()}
            size='large'
            variant='contained'
            sx={{
                borderRadius: 3,
                display: 'inline-flex',
                height: "10vh",
                textAlign: 'center',
                alighContent: 'center',
                justifyContent: 'center',
                gap: '8px',
                margin: '100px 0px',
                border: '1px solid #E4E4E4',
                background: color.primary,
                width: '70%',
                m: 1,
                "&:hover": {
                    background: color.primary,
                }
            }}
        >
            <Typography size='body6' sx={{
                color: '#FFF',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '130%', /* 26px */
                letterSpacing: '-0.4px',
                textTransform: 'none',
            }}>
                Sign In
            </Typography>
            <ArrowForwardIcon />
        </Button>
    </>;
};

export default LoginButton;