import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Chip, Stack } from '@mui/material';
import { useState } from 'react';
import { CONFIGURE_OPTIONS } from '../../Configs/JobConstants';
import { color } from '../../Styles/Color';
import CustomToggleButton from '../UiComponents/CustomToggleButton';
import { CloudConfig } from './CloudConfig';
import { OnPremiseConfig } from './OnPremiseConfig';

export const GPUConfig = ({
  cloudGpuWorkerConfig, setCloudGPUWorkerConfig,
  gpuTypeOptions, cloudProviderOptions, regionOptions, instanceOptions, gpuOptions, cloudProvider, setCloudProvider, region, setRegion,
  // setSpotInstance,  gpuNodes, setGpuNodes, gputype, setGputype, minWorker, setMinWorker, allowOtherGPU, setAllowOtherGPU, useSpotInstace,  setCloudBurst, cloudBurst,gpuNodeType, setGPUNodeType, numberOfGPUs, setNumberOfGPUs,  instanceType, setInstancetype, gpuPerNode, setGPUPerNode,
  register, watch, setValue, infType, isFetchingGPUs, isFetchingInstances
}) => {

  const [selectedConfig, setSelectedConfig] = useState(CONFIGURE_OPTIONS[0])

  useState(() => {
    setValue("initial_worker_config.use_on_prem", selectedConfig === CONFIGURE_OPTIONS[1])
  }, [selectedConfig])

  return (
    <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Chip
        sx={
          {
            bgcolor: "#FFFFFF",
            color: color.primary,
            width: "25%",
            borderRadius: "6px",
            fontSize: "15px"
          }
        }
        label={
          <Stack gap={1} direction="row" alignItems="center">
            <AppRegistrationIcon sx={{ color: color.primary, fontSize: "16px" }} />
            Configure
          </Stack>
        } />
      <CustomToggleButton options={CONFIGURE_OPTIONS} selected={selectedConfig} setSelected={setSelectedConfig} />
      {
        selectedConfig === CONFIGURE_OPTIONS[1] ?
          <OnPremiseConfig
            // setGpuNodes={setGpuNodes} gpuNodes={gpuNodes}
            // setCloudBurst={setCloudBurst} cloudBurst={cloudBurst} gputype={gputype} setGputype={setGputype} minWorker={minWorker} setGPUNodeType={setGPUNodeType} gpuNodeType={gpuNodeType}
            // setMinWorker={setMinWorker} allowOtherGPU={allowOtherGPU}
            // setAllowOtherGPU={setAllowOtherGPU} numberOfGPUs={numberOfGPUs} setNumberOfGPUs={setNumberOfGPUs}
            // useSpotInstace={useSpotInstace} 
            // setSpotInstance={setSpotInstance} instanceType={instanceType} setInstancetype={setInstancetype}  gpuPerNode={gpuPerNode} setGPUPerNode={setGPUPerNode} 
            cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
            region={region} setRegion={setRegion}
            gpuOptions={gpuOptions}
            gpuTypeOptions={gpuTypeOptions}
            cloudProviderOptions={cloudProviderOptions} regionOptions={regionOptions}
            instanceOptions={instanceOptions}
            register={register} setValue={setValue} watch={watch}
            cloudGpuWorkerConfig={cloudGpuWorkerConfig} setCloudGPUWorkerConfig={setCloudGPUWorkerConfig}
          />
          :
          <CloudConfig
            // gputype={gputype} setGputype={setGputype} minWorker={minWorker}
            //   numberOfGPUs={numberOfGPUs} setNumberOfGPUs={setNumberOfGPUs}
            // setMinWorker={setMinWorker} allowOtherGPU={allowOtherGPU}
            //   setAllowOtherGPU={setAllowOtherGPU} instanceType={instanceType} setInstancetype={setInstancetype}
            //   useSpotInstace={useSpotInstace}
            // setSpotInstance={setSpotInstance} 
            cloudGpuWorkerConfig={cloudGpuWorkerConfig} setCloudGPUWorkerConfig={setCloudGPUWorkerConfig}
            cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
            region={region} setRegion={setRegion}
            gpuOptions={gpuTypeOptions}
            cloudProviderOptions={cloudProviderOptions}
            regionOptions={regionOptions}
            instanceOptions={instanceOptions}
            register={register} setValue={setValue} watch={watch} infType={infType}
            isFetchingGPUs={isFetchingGPUs} isFetchingInstances={isFetchingInstances}

          />
      }
    </Stack >
  )
}
