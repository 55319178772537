import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { Chip, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { color } from '../../Styles/Color';

export const APIGatewayCongif = ({ watch, setValue, userClouds }) => {
  const regions = userClouds.find(c => c.cloud_provider === watch("gateway_cloud_config.name")).regions

  useEffect(() => {
    if (regions && regions.length > 0) {
      setValue("gateway_cloud_config.region", regions[0])
    }
  }, [regions, setValue])

  return (
    <Stack bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Stack py={2} px={3} spacing={2}>
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "25%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <CloudSyncIcon sx={{ color: color.primary, fontSize: "16px" }} />
              API Gateway Configurations
            </Stack>
          } />
        <Typography fontWeight="600" fontSize="15px">
          API Gateway Cloud
        </Typography>
        <Select
          displayEmpty
          size='small'
          sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
          value={watch("gateway_cloud_config.name")}
          onChange={(e) => setValue("gateway_cloud_config.name", e.target.value)}
        >
          <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
            Choose API Gate-way Cloud
          </MenuItem>
          {
            userClouds.filter(c => c.cloud_provider === "AWS").map((cloud, idx) => (
              <MenuItem value={cloud.cloud_provider} key={idx} sx={{ fontSize: "15px" }}>
                {cloud.cloud_provider}
              </MenuItem>
            ))
          }
          {
            userClouds.filter(c => c.cloud_provider !== "AWS").map((cloud, idx) => (
              <MenuItem value={cloud.cloud_provider} disabled key={idx + 1} sx={{ fontSize: "15px" }}>
                {cloud.cloud_provider}
              </MenuItem>
            ))
          }
        </Select>
        <Typography fontWeight="600" fontSize="15px">
          API Gateway Region
        </Typography>
        <Select
          displayEmpty
          size='small'
          sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
          value={watch("gateway_cloud_config.region")}
          onChange={(e) => setValue("gateway_cloud_config.region", e.target.value)}
        >
          <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
            Choose API Gate-way Region
          </MenuItem>
          {
            regions?.map((path, idx) => (
              <MenuItem value={path} key={idx} sx={{ fontSize: "15px" }}>
                {path}
              </MenuItem>
            ))
          }
        </Select>
      </Stack>
    </Stack >

  )
}
