import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { ON_PREM_NODE_TYPE_MAP } from '../../Configs/OnPremNodesContants';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';

function createData(id, ip, username, isVerified, verification_message, port, gpu_type, gpu_count) {
  return { id, ip, username, isVerified, verification_message, port, gpu_type, gpu_count };
}

export default function NodesList({ data, handleDelete, type }) {

  const rows = (data || []).map((d) =>
    createData(d.id, d.ip, d.username, d.verified, d.verification_message, d.port, d.gpu_type, d.gpu_count))


  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000029",
        maxHeight: "30vh",
      }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Node IP</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Username</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%">Port</TableCell>
            {
              type === ON_PREM_NODE_TYPE_MAP.WORKER &&
              <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">GPU</TableCell>
            }
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%">Verified</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">
              Verification message
            </TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.map((row, idx) => (
                <TableRow
                  hover
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: "13px" }}>
                    {row.ip}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.username}</TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.port}</TableCell>
                  {
                    type === ON_PREM_NODE_TYPE_MAP.WORKER &&
                    <TableCell sx={{ fontSize: "13px" }}>{row.gpu_type} x {row.gpu_count}</TableCell>
                  }
                  <TableCell>
                    {
                      row.isVerified ?
                        <CheckIcon sx={{ fontSize: "20px", color: "#2C974A" }} /> :
                        <ClearIcon sx={{ fontSize: "20px", color: "#FF6B6A" }} />
                    }
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.verification_message}</TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(row.id)
                      }}
                    />
                  </TableCell>
                </TableRow>
              ))
              :
              <Stack height="10vh" justifyContent="center" px={2}>
                <Typography color={color.primary} fontSize="15px">No data available</Typography>
              </Stack>
          }
        </TableBody>
      </Table>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="On-prem Node"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  );
}