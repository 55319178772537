import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import { Box, Divider, Drawer, List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
// import whiteLogo from '../../Assets/Images/logo_scalegenai/logo_white.png';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { ADMIN_MENU_ITEMS, MENU_ITEM_ICONS, SIDE_NAVBAR_INFO_MENU_ITEMS, SIDE_NAVBAR_JOB_MENU_ITEMS } from '../../Configs/SideNavBarConstants';
import '../../Styles/App.css';
import { color } from '../../Styles/Color';
import NavBarItemDrawer from './NavBarItemDrawer';


const NavBar = ({ isAdmin }) => {

  const ref = useRef(null);
  const nav = useNavigate()

  const user = useSelector(state => state.userState.user)

  const adminMenuItems = isAdmin ? [...Object.values(ADMIN_MENU_ITEMS)] : [];

  const jobMenuItems = [...Object.values(SIDE_NAVBAR_JOB_MENU_ITEMS)];

  const infoMenuItems = [...Object.values(SIDE_NAVBAR_INFO_MENU_ITEMS)];

  const productLogo = process.env.REACT_APP_PRODUCT_LARGE_LOGO

  return (
    <>
      <Drawer
        anchor='left'
        variant='permanent'
        sx={{
          display: { xs: 'none', md: 'block' },
          '& .MuiDrawer-paper': {
            width: '20%',
            border: 'none',
          },
        }}
        open
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '97%',
          padding: 1,
          margin: 1,
          borderRadius: '16px',
          border: '2px solid #EAEAEA',
          background: color.primary,
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.16)',
        }} ref={ref}>
          <Stack
            // height="60px"
            justifyContent="center"
            alignItems="center"
            sx={{ cursor: "pointer" }}
          // py={0.5}
          >
            {productLogo ?
              <img
                src={productLogo}
                style={{
                  maxWidth: "180px",
                  height: "60px"
                }}
                alt={process.env.REACT_APP_PRODUCT_TYPE}
                onClick={() => nav(PAGE_ROUTES.dashboard)}
              />
              : <Typography
                color={color.white}
                fontSize="32px" fontWeight={800}
                onClick={() => nav(PAGE_ROUTES.dashboard)}
              >
                {process.env.REACT_APP_PRODUCT_TYPE}
              </Typography>
            }
          </Stack>
          <Divider
            variant='middle'
            sx={{
              bgcolor: '#E4E4E4',
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box
              sx={{
                overflow: 'auto',
                height: "68vh",
                '&::-webkit-scrollbar': {
                  display: 'none',
                },
              }}
            >
              {adminMenuItems.length > 0 && (
                <>
                  <NavBarItemDrawer menuItems={adminMenuItems} />
                  <Divider variant='middle' sx={{ borderStyle: 'dashed' }} />
                </>
              )}
              <NavBarItemDrawer menuItems={jobMenuItems} />
              <Divider variant='middle' sx={{ borderStyle: 'dashed' }} />
              <NavBarItemDrawer menuItems={infoMenuItems} />
            </Box>
            <nav aria-label='left menu'>
              <List>
                <ListItemButton
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => window.open("https://docs.scalegen.ai", "_blank")}
                  disableRipple
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderRadius: '12px',
                      border: '1px solid #E4E4E4',
                      background: color.primary,
                      width: '100%',
                    }}
                  >
                    <ListItemIcon sx={{
                      '& svg': {
                        color: '#FFFFFF',
                        fontSize: '24px',
                      },
                    }}>
                      {MENU_ITEM_ICONS['DOCUMENTATION']}
                    </ListItemIcon>
                    <ListItemText
                      primary='Documentation'
                      sx={{
                        '& span': {
                          fontFamily: 'IBMPlexSansSemiBold',
                          color: '#FFFFFF',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                        },
                      }}
                    />
                    <ArrowRightRoundedIcon sx={{
                      color: '#FFFFFF',
                      fontSize: '32px',
                    }} />
                  </Box>
                </ListItemButton>
                <ListItemButton
                  sx={{
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  disableRipple
                  onClick={() => nav(PAGE_ROUTES.setting)}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center', // this will vertically center the contents
                      borderRadius: '16px',
                      border: '1px solid #E4E4E4',
                      background: '#FFFFFF',
                      width: '100%',
                      padding: '5px',
                      // '&:hover': {
                      //   background: '#E4E4E4', // change background color on hover
                      // }
                    }}
                  >
                    <ListItemIcon sx={{
                      '& svg': {
                        color: '#FFFFFF',
                        fontSize: '24px',
                      },
                    }}>
                      <Avatar alt={user.nickname} src={user.picture} sx={{
                        width: '32px',
                        height: '32px',
                      }} />
                    </ListItemIcon>
                    <ListItemText
                      primary='Profile'
                      sx={{
                        '& span': {
                          fontFamily: 'IBMPlexSansSemiBold',
                          color: '#181818',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                        },
                      }}
                    />
                    <SettingsRoundedIcon sx={{
                      color: '#ABABAB',
                      mr: 0.5
                    }} fontSize="small" />
                  </Box>
                </ListItemButton>
              </List>
            </nav>
          </Box>
        </Box>
      </Drawer>
    </>
  );
}

export default NavBar;
