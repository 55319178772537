import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import AddVMModal from '../Components/VirtualMountsComponents/AddVMModal';
import VMList from '../Components/VirtualMountsComponents/VMList';
import { openVMModal } from '../DataStore/modalSlice';
import { useDeleteVirtualMountMutation, useGetVirtualMountsQuery } from '../Services/virtualMountsApi';


const VirtualMountsView = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetVirtualMountsQuery();
  const [handleDelete, { isLoading: isDeleting }] = useDeleteVirtualMountMutation()

  if (isLoading || isDeleting) {
    return <Loader />
  }

  return (
    <Stack>
      <Stack spacing={2}>
        <Typography fontFamily="IBMPlexSansBold" fontSize="25px" pb={2}>Virtual Mounts</Typography>
        <Stack alignItems="end">
          <CustomButton width="30%" onClick={() => dispatch(openVMModal())}>
            <AddIcon fontSize='small' sx={{ mr: 1 }} />
            Add Virtual Mount
          </CustomButton>
        </Stack>
        <VMList data={data} handleDelete={handleDelete} />
      </Stack>
      <AddVMModal />
    </Stack >
  )
}

export default VirtualMountsView