import { TextField } from '@mui/material';
import React from 'react';

const InputField = (
  { state, setState, placeholder, width, disabled, multiLine, type, register, error, field, required, watch }
) => {

  const handleWheel = (event) => {
    event.target.blur(); // Prevent scrolling when using the mouse wheel
  };

  return (
    <TextField
      onWheel={handleWheel}
      type={type ? type : 'text'}
      multiline={multiLine}
      rows={multiLine ? 4 : 1}
      disabled={disabled}
      size='small'
      value={(field ? watch(field) : state) || ""}
      onChange={setState}
      sx={{
        fontFamily: "IBMPlexMonoSemiBold",
        width: `${width ? width : "100%"}`,
      }}
      placeholder={placeholder || "Enter value"}
      InputProps={{
        style: {
          fontFamily: "IBMPlexMonoSemiBold", fontSize: "15px",
          borderRadius: "8px",
          backgroundColor: "#ffffff",
        },
      }}
      {...register && {
        ...register(field, required && { required: { value: true, message: "This field is required" } })
      }}
      error={error ? true : false}
      helperText={error?.message}
    />
  )
}

export default InputField