import { Box, Checkbox, Chip, FormControlLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { GPU_CONFIG_TABS } from '../../Configs/ConfigureNewJobConstants'
import { CLOUD_CONFIG_WORKER_OPTIONS } from '../../Configs/JobConstants'
import { color } from '../../Styles/Color'
import BoxSwitch from '../UiComponents/BoxSwitch'
import CustomToggleButton from '../UiComponents/CustomToggleButton'
import InputField from '../UiComponents/InputField'
import NavigationTabs from '../UiComponents/NavigationTabs'

export const CloudConfig = ({
  gpuOptions, instanceOptions, cloudProviderOptions, regionOptions,
  // gputype, setGputype, minWorker, setMinWorker, allowOtherGPU, setAllowOtherGPU, setSpotInstance, 
  // instanceType, setInstancetype, numberOfGPUs, setNumberOfGPUs,
  register, watch, setValue, cloudProvider, setCloudProvider, region, setRegion,
  cloudGpuWorkerConfig, setCloudGPUWorkerConfig, infType, isFetchingGPUs, isFetchingInstances
}) => {

  const [cloudConfigActiveTab, setCloudConfigActiveTab] = useState("0")

  useEffect(() => {
    if (cloudProviderOptions?.length !== cloudProvider?.length) {
      setRegion(prev => prev.filter(r => cloudProvider.includes(r.split(":")[0])))
    }
  }, [cloudProvider, cloudProviderOptions, setRegion])

  const CLOUD_CONFIG_PANELS = [
    <Stack spacing={2}>
      <Stack spacing={2} >
        <Typography fontWeight="600" fontSize="15px">
          Preferred GPU Type
        </Typography>
        <Select
          displayEmpty
          size='small'
          sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
          value={watch("initial_worker_config.initial_workers_gpu") || ""}
          onChange={(e) => {
            if (e.target.value === "") {
              setValue("initial_worker_config.initial_workers_gpu", null)
            }
            setValue("initial_worker_config.initial_workers_gpu", e.target.value)
          }}
        >
          <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
            {isFetchingGPUs ? "...Loading" : "Auto-Select"}
          </MenuItem>
          {
            (gpuOptions?.filter(gpu => gpu.length > 0) || []).map((option, idx) => (
              <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
                {option}
              </MenuItem>
            ))
          }
        </Select>
        <Stack gap={1} >
          <Typography fontSize="15px">Number of GPUs Per Worker</Typography>
          <InputField
            state={watch("initial_worker_config.initial_workers_gpu_num") || ""}
            setState={(e) => {
              if (e.target.value === "") {
                setValue("initial_worker_config.initial_workers_gpu_num", null)
              }
              setValue("initial_worker_config.initial_workers_gpu_num", e.target.value)
            }}
            width="70%"
            placeholder="Auto-Select"
          />
        </Stack>

        <FormControlLabel sx={{ py: 1 }}
          control={<Checkbox
            checked={watch("initial_worker_config.use_other_gpus")}
            onChange={(e) => setValue("initial_worker_config.use_other_gpus", e.target.checked)}
          />}
          label={<Stack direction="row" alignItems="center">
            <Typography fontSize="17px" mx={1}>Allow other GPU types</Typography>
          </Stack >}
        />
      </Stack>
    </Stack>,
    <Stack spacing={2}>
      <Typography fontWeight="600" fontSize="15px">
        Choose Instance Type
      </Typography>
      <Select
        size='small'
        multiple
        displayEmpty
        value={watch("initial_worker_config.instance_types") || []}
        onChange={(e) => setValue("initial_worker_config.instance_types", e.target.value)}
        input={<OutlinedInput placeholder='Choose Instance types' />}
        renderValue={(selected, idx) => {
          return (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography fontSize="15px">Choose Instance types</Typography>
                  :
                  selected.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))
              }
            </Box>
          )
        }}
        placeholder='Choose Instance Type'
        sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
      >
        <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
          {isFetchingInstances ? "...Loading" : "Auto-Select"}
        </MenuItem>
        {
          (instanceOptions || []).map((option, idx) => (
            <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
              {option}
            </MenuItem>
          ))
        }
      </Select>
    </Stack>
  ]

  return (
    <Stack spacing={2} px={2}>
      {
        infType === "llm" &&
        <CustomToggleButton options={CLOUD_CONFIG_WORKER_OPTIONS}
          selected={cloudGpuWorkerConfig} setSelected={setCloudGPUWorkerConfig} />
      }
      <Stack px={2} spacing={2}>
        <FormControlLabel sx={{ py: 2 }}
          value={watch("allow_spot_instances")}
          control={
            <BoxSwitch
              onChange={(e) => setValue("allow_spot_instances", e.target.checked)}
            />
          }
          label={<Stack direction="row" alignItems="center">
            <Typography fontSize="17px" mx={1}>Use Spot Instances</Typography>
          </Stack >}
        />
        {
          (cloudGpuWorkerConfig !== CLOUD_CONFIG_WORKER_OPTIONS[0] || infType === "embedding") ?
            <Stack spacing={2}>
              <Typography fontSize="15px">Min. Fixed Workers</Typography>
              <InputField
                state={watch("initial_worker_config.min_workers")}
                setState={(e) => {
                  if (e.target.value === "0") {
                    setValue("autoscaling_config.scale_to_zero", true)
                  } else {
                    setValue("autoscaling_config.scale_to_zero", false)
                  }
                  setValue("initial_worker_config.min_workers", e.target.value)
                }}
                width="70%"
              />
              <NavigationTabs tabs={GPU_CONFIG_TABS} panels={CLOUD_CONFIG_PANELS} value={cloudConfigActiveTab}
                setValue={setCloudConfigActiveTab} />
            </Stack>
            :
            <Stack spacing={2} >
              <Typography fontSize="15px">Min. throughput (tokens/sec)</Typography>
              <InputField
                register={register}
                field="min_throughput_rate"
                watch={watch}
                width="70%" />
            </Stack>
        }
      </Stack>
      <Stack gap={1.5} p={2}>
        <Typography fontWeight="600" fontSize="15px">
          Cloud Provider
        </Typography>
        <Select
          size='small'
          multiple
          displayEmpty
          value={cloudProvider}
          onChange={(e) => setCloudProvider(e.target.value)}
          input={<OutlinedInput />}
          renderValue={(selected, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography fontSize="15px">Choose Cloud Providers</Typography>
                  : selected.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))
              }
            </Box>
          )}
          sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
        >
          {
            (cloudProviderOptions
              ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
              || []).map((cloud, idx) => (
                <MenuItem
                  key={idx}
                  value={cloud.cloud_provider}
                  sx={{ fontSize: "15px" }}
                >
                  {cloud.cloud_provider}
                </MenuItem>
              ))
          }
          <MenuItem
            value="SCALEGENAI"
            sx={{ fontSize: "15px" }}
          >
            SCALEGENAI
          </MenuItem>
        </Select>
        <Typography fontWeight="600" fontSize="15px">
          Cloud Region
        </Typography>
        <Select
          size='small'
          multiple
          displayEmpty
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          input={<OutlinedInput />}
          renderValue={(selected, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography fontSize="15px">Choose the region where you want to train</Typography>
                  : selected.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))}
            </Box>
          )}
          sx={{ bgcolor: color.white, borderRadius: "8px" }}
        >
          {
            (regionOptions?.filter(region => cloudProvider.includes(region.split(":")[0])) || [])
              .map((region, idx) => (
                <MenuItem
                  key={idx}
                  value={region}
                  sx={{ fontSize: "15px" }}
                >
                  {region}
                </MenuItem>
              ))
          }
        </Select>
      </Stack>
    </Stack >
  )
}
