import { Typography } from "@mui/material"
import { color } from "../../Styles/Color"

export const RequiredHeader = ({ children }) => {
  return (
    <Typography fontSize="15px" display="flex" flexDirection="row" gap={1}>
      {children}
      <Typography fontSize="15px" color={color.error}>*</Typography>
    </Typography>
  )
}
