import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import { Autocomplete, Chip, Stack, TextField, Typography } from "@mui/material";
import { useGetModelsQuery } from '../../Services/inferenceApi';
import { color } from '../../Styles/Color';
import InputField from '../UiComponents/InputField';
import { RequiredHeader } from '../UiComponents/RequiredHeader';


const Configure = ({
  // model, setModel, faceToken, setFaceToken, 
  infType, register, watch, setValue, errors
}) => {

  const { data } = useGetModelsQuery()

  return (
    <Stack spacing={2}>
      <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px"
        border={`1px solid ${color.borders}`}>
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "25%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <DeviceHubIcon sx={{ color: color.primary, fontSize: "16px" }} />
              Model
            </Stack>
          } />
        <Stack gap={1} >
          <RequiredHeader>Model</RequiredHeader>
          <InputField placeholder="Enter Model Name"
            // state={faceToken} setState={(e) => setFaceToken(e.target.value)} 
            register={register}
            field="model"
            error={errors?.model}
            required={true}
            width="70%"
            watch={watch}
          />
        </Stack>
        <Stack gap={1} >
          <Typography fontSize="15px">Base Model</Typography>
          <Autocomplete
            size="small"
            sx={{ fontSize: '15px', width: "70%", background: '#fff', borderRadius: "8px" }}
            value={watch("base_model")}
            onChange={(e, newValue) => setValue("base_model", newValue)}
            options={data?.filter(d => d.type === infType)?.map(d => d.model) || []}
            renderInput={(params) =>
              <TextField {...params}
                InputProps={{ ...params.InputProps, style: { fontSize: "15px", borderRadius: '8px' } }}
                placeholder="Choose a Model"
                error={errors?.base_model ? true : false}
              />
            }
            disableClearable
            freeSolo
            renderOption={(props, option, state) => <Typography {...props} fontSize="15px">{option}</Typography>}
          />
        </Stack>
        <Stack gap={1} >
          <Typography fontSize="15px">HuggingFace Token</Typography>
          <InputField placeholder="Enter HuggingFace Token"
            // state={faceToken} setState={(e) => setFaceToken(e.target.value)} 
            register={register}
            watch={watch}
            field="hf_token"
            error={errors?.hf_token}
            // required={true}
            width="70%" />
        </Stack>

      </Stack >
    </Stack >
  )
}

export default Configure