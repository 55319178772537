import { Stack, Typography } from '@mui/material'
import { SecretsList } from '../Components/SecretsViewComponents/SecretsList'
import Loader from "../Components/UiComponents/Loader"
import { useGetSecretsQuery } from '../Services/secretsApi'

export const SecretsView = () => {

  const { data, isLoading } = useGetSecretsQuery()

  if (isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack spacing={2}>
      <Typography fontFamily="IBMPlexSansBold" fontSize="25px" pb={2}>Secrets</Typography>
      <SecretsList data={data} />
    </Stack>
  )
}
