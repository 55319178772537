import ClearIcon from '@mui/icons-material/Clear';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloudIcon } from "../../Assets/Images/wb_cloudy.svg";
import { setErrorMessage, setIsError } from '../../DataStore/errorSlice';
import { closeCloudProviderModal } from '../../DataStore/modalSlice';
import { useAddCloudProviderMutation } from '../../Services/cloudProviderApi';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';
import ProviderCredtials from './ProviderCredtials';
import ProviderOptions from './ProviderOptions';


const ChooseProviderModal = () => {
  const [isActive, setIsActive] = useState("");
  const initialFormState = Object.freeze({
    "cloud_provider": "",
    "bucket_name": "some-random-bucket",
    "primary": false,
    "regions": [],
    "creds": {}
  })

  const [formValues, setFormValues] = useState(initialFormState)

  const [submit, { isLoading, isSuccess }] = useAddCloudProviderMutation();
  const { isCloudProviderModalOpen } = useSelector((store) => store.modal)

  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess) handleClose()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleClose = () => {
    setIsActive("")
    setFormValues(initialFormState)
    dispatch(closeCloudProviderModal())
  }

  const handleNext = () => {
    setFormValues({ ...formValues, cloud_provider: isActive })
  }

  const handleAdd = () => {
    let isValid = true
    let errorField = ""
    Object.keys(formValues.creds).forEach(cred => {
      if (formValues.creds[cred]?.length === 0) {
        isValid = false
        errorField = cred
      }
    })
    if (isValid) {
      submit(formValues);
    } else {
      dispatch(setIsError(true))
      dispatch(setErrorMessage(errorField.replaceAll("_", " ") + " is Required"));
    }
  }

  return (
    <Dialog
      maxWidth="xl"
      open={isCloudProviderModalOpen}
      onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}
    >
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <CloudIcon width="20px" height="20px" />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Add Cloud Provider</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "50vh",
        width: "50vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        {
          isLoading ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            <>
              {
                !formValues.cloud_provider ?
                  <ProviderOptions setIsActive={setIsActive} isActive={isActive} />
                  :
                  <ProviderCredtials
                    formValues={formValues}
                    setFormValues={setFormValues}
                  />
              }
            </>
        }
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={!formValues.cloud_provider ? handleNext : handleAdd}
          width="10%"
        >
          {!formValues.provider ? "Next" : "Add"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default ChooseProviderModal