import { useAuth0 } from '@auth0/auth0-react';
import SourceIcon from '@mui/icons-material/Source';
import { Avatar, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
;

const Profile = () => {
  const user = useSelector(state => state.userState.user)
  const { logout } = useAuth0();

  return (
    <Stack
      borderRadius="12px"
      border="2px solid #E4E4E4"
      pt={1}
      pb={3}
      px={1.5}
      spacing={4}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <Stack border="2px solid #96DBF1" borderRadius="8px" p={0.5}>
            <SourceIcon sx={{ color: color.primary }} />
          </Stack>
          <Typography fontSize="20px" color={color.primary}>Profile</Typography>
        </Stack>
        <Stack direction="row" justifyContent="end" width="100%" >
          <CustomButton
            color="#FF6B6A" width="200px"
            onClick={() =>
              logout({
                logoutParams: {
                  returnTo: window.location.origin
                }
              })
            }
          >Logout
          </CustomButton>
        </Stack>
      </Stack>
      <Stack px={2} direction="row">
        <Avatar
          alt={user.nickname}
          src={user.picture}
          sx={{
            width: '100px',
            height: '100px',
          }}
        />
        <Stack px={4} width="70%" spacing={2}>
          <Typography fontSize="26px">{user.nickname}</Typography>
          <Typography fontSize="15px" color="#ABABAB">E-mail</Typography>
          <Typography fontSize="16px">{user.email}</Typography>
        </Stack>
      </Stack>
    </Stack >
  )
}

export default Profile