import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Alert, Chip, FormControlLabel, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useGetGPUNodesQuery, useScaleInferenceMutation, useUpdateInferenceMutation } from '../../Services/inferenceApi'
import { useGetSecretQuery } from '../../Services/secretsApi'
import { color } from '../../Styles/Color'
import BoxSwitch from '../UiComponents/BoxSwitch'
import CustomButton from '../UiComponents/CustomButton'
import InputField from '../UiComponents/InputField'
import Loader from '../UiComponents/Loader'
import SecondaryButton from '../UiComponents/SecondaryButton'
import { getStatus } from './InferenceList'

export const InferenceDetails = ({
  inferenceDetails, editableConfig, setIsDeleteModalOpen, setEditableConfig
}) => {
  const location = useLocation()

  const { data: gpuNodes, isLoading: isLoadingGPUNodes } =
    useGetGPUNodesQuery(location.pathname.split('/').pop(), {
      skip: !inferenceDetails || (inferenceDetails?.status === "DELETED" || inferenceDetails?.status === "FAILED")
    })

  const { data: secret, isLoading: isLoadingSecret } =
    useGetSecretQuery("AUTH_ENDPOINT_KEY_" + location.pathname.split('/').pop())

  const [handleUpdate, { isLoading: isUpdating }] = useUpdateInferenceMutation()

  const [scaleInference, { isLoading: isScaling }] = useScaleInferenceMutation()

  const [isEditing, setIsEditing] = useState(false)

  const handleSave = () => {
    handleUpdate(
      {
        "config": {
          "initial_worker_config":
            inferenceDetails.initial_worker_config ?
              {
                ...inferenceDetails.initial_worker_config,
                "min_workers": editableConfig.min_workers
              }
              :
              null
          ,
          "autoscaling_config":
            inferenceDetails.autoscaling_config ?
              inferenceDetails.autoscaling_config :
              {
                "time_window_sec": 300,
                "upper_allowed_latency_sec": 4,
                "lower_allowed_latency_sec": 1,
                "scaling_up_timeout_sec": 1200,
                "scaling_down_timeout_sec": 1200,
                "scale_to_zero_timeout_sec": 1800,
                "enable_speedup_shared": false
              },
          "imidiate_scale_down": false,
          "max_price_per_hour": editableConfig.max_price_per_hour,
          "min_throughput_rate": editableConfig?.min_throughput_rate,
          "allow_spot_instances": editableConfig.allow_spot_instances
        },
        "id": inferenceDetails.id
      }
    )
    setIsEditing(false)
  }

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
  }

  if (isLoadingSecret || isLoadingGPUNodes || isUpdating || isScaling) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack
      spacing={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
      height="77vh"
      pb={1}
    >
      <Stack p={1} pb={2} spacing={2}
        bgcolor={color.secondaryBackground} borderRadius="8px"
        border={`1px solid ${color.borders}`}
      >
        <Stack width="15%">
          {getStatus(inferenceDetails?.status)}
        </Stack>
        <Stack px={3} spacing={2}>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Inference Deployment ID</Typography>
            <Typography color={color.primary} fontSize="15px">
              {inferenceDetails.id}
            </Typography>
            <Tooltip title={isCopied ? "Copied" : "Copy"}>
              <ContentCopyIcon
                sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                onClick={() => handleCopyClick(inferenceDetails.id)}
              />
            </Tooltip>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Model</Typography>
            <Typography color={color.primary} fontSize="15px">
              {inferenceDetails.model}
            </Typography>
            <Tooltip title={isCopied ? "Copied" : "Copy"}>
              <ContentCopyIcon
                sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                onClick={() => handleCopyClick(inferenceDetails.model)}
              />
            </Tooltip>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Current Price Per Hour ($)</Typography>
            <Chip
              sx={{
                bgcolor: color.lightBlue,
                color: color.primary,
                borderRadius: "20px"
              }}
              label={
                <Stack direction="row" alignItems="center" gap={1} fontSize="12px">
                  {inferenceDetails.current_price_per_hour.toFixed(4)}
                </Stack>
              }
            />
          </Stack>
          {
            inferenceDetails.link &&
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">API Endpoint</Typography>
              <Typography color={color.primary} fontSize="15px">
                {inferenceDetails.link + (inferenceDetails?.inf_type !== "llm" ? "/embed/v1" : "/inference/v1")}
              </Typography>
              <Tooltip title={isCopied ? "Copied" : "Copy"}>
                <ContentCopyIcon
                  sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                  onClick={() =>
                    handleCopyClick(
                      inferenceDetails.link +
                      (inferenceDetails?.inf_type !== "llm" ? "/embed/v1" : "/inference/v1")
                    )
                  }
                />
              </Tooltip>
            </Stack >
          }
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">API key</Typography>
            {
              secret ?
                <Stack direction="row" gap={1} minWidth="40%" alignItems="center">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ?
                            <VisibilityOff
                              sx={{ fontSize: "24px" }}
                            /> :
                            <Visibility
                              sx={{ fontSize: "24px" }}
                            />}
                        </IconButton>
                      </InputAdornment>
                    }
                    value={secret?.secret["AUTH_ENDPOINT_KEY_" + inferenceDetails.id]}
                    onChange={() => { }}
                    sx={{
                      fontSize: "15px",
                      width: "100%",
                      color: color.primary,
                    }}
                    disableUnderline
                  />
                  <Tooltip title={isCopied ? "Copied" : "Copy"}>
                    <ContentCopyIcon
                      sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                      onClick={() => handleCopyClick(secret?.secret["AUTH_ENDPOINT_KEY_" + inferenceDetails.id])}
                    />
                  </Tooltip>
                </Stack>
                :
                <Typography fontSize="15px">( not available )</Typography>
            }
          </Stack >
          {
            inferenceDetails.link &&
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">Metrics Dashboard</Typography>
              <Link to={inferenceDetails.link + "/metrics"} target="_blank">
                <Typography color={color.primary} fontSize="15px">
                  {inferenceDetails.link + "/metrics"}
                </Typography>
              </Link>
            </Stack >
          }
          <Alert severity="info" square={false} sx={{ width: "80%", fontSize: "14px" }}>
            For Metrics dashboard login with Inference Deployment ID as username and API key as password
            {/* {
              inferenceDetails.status === "ACTIVE" && inferenceDetails?.inf_type === "llm" &&
              " and For Testing deployment use above API key and API endpoint."
            } */}
          </Alert>
        </Stack >
        <Stack direction="row" justifyContent="end" gap={1} width="95%">
          {
            !(inferenceDetails.status === "DELETED" || inferenceDetails.status === "DELETING") &&
            <CustomButton width="20%" onClick={() => setIsDeleteModalOpen(true)} color="#FF6B6A">
              Delete
            </CustomButton>
          }
          {
            inferenceDetails.status === "INACTIVE" &&
            <CustomButton width="20%"
              onClick={() => scaleInference(
                {
                  id: inferenceDetails.id,
                  direction: "up"
                }
              )}
              color="#FF6B6A"
            >
              Start Deployment
            </CustomButton>
          }
          {
            inferenceDetails.status === "ACTIVE" && inferenceDetails?.inf_type === "llm" &&
            inferenceDetails?.link?.length > 0 &&
            <Link style={{ width: "20%" }} to={inferenceDetails.link + "/chat"} target="_blank">
              <CustomButton color="#FF6B6A">
                Test Deployment
              </CustomButton>
            </Link>
          }
        </Stack>
      </Stack >
      <Stack direction="row" gap={2}>
        <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} width="50%">
          <Chip
            sx={
              {
                bgcolor: "#FFFFFF",
                color: color.primary,
                width: "25%",
                borderRadius: "6px",
                fontSize: "15px"
              }
            }
            label={
              <Stack gap={1} direction="row" alignItems="center">
                Auto-Scaling
              </Stack>
            } />
          {/* <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Mean Latency Threshold</Typography>
          </Stack> */}
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Mean Latency Threshold (sec)</Typography>
            <Typography color={color.primary} fontSize="15px">
              {inferenceDetails?.autoscaling_config?.upper_allowed_latency_sec || 0}
            </Typography>
          </Stack>
          {/* <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Look-back Period (secs)</Typography>
            <Typography color={color.primary} fontSize="15px">
              {inferenceDetails?.autoscaling_config?.time_window_sec || 0}
            </Typography>
          </Stack> */}
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Scale-up Time Window (secs)</Typography>
            <Typography color={color.primary} fontSize="15px">
              {inferenceDetails?.autoscaling_config?.scale_up_time_window_sec || 0}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Scale-down Time Window (secs)</Typography>
            <Typography color={color.primary} fontSize="15px">
              {inferenceDetails?.autoscaling_config?.scale_down_time_window_sec || 0}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Scale to zero </Typography>
            <Typography color={color.primary} fontSize="15px">
              {(inferenceDetails?.autoscaling_config?.scale_to_zero || false).toString()}
            </Typography>
          </Stack>
          {
            inferenceDetails?.autoscaling_config.scale_to_zero &&
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">Scale to zero timeout (sec)</Typography>
              <Typography color={color.primary} fontSize="15px">
                {inferenceDetails?.autoscaling_config?.scale_to_zero_timeout_sec || 0}
              </Typography>
            </Stack>
          }
        </Stack >
        <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} width="50%">

          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Chip
              sx={
                {
                  bgcolor: "#FFFFFF",
                  color: color.primary,
                  width: "30%",
                  borderRadius: "6px",
                  fontSize: "15px"
                }
              }
              label={
                <Stack gap={1} direction="row" alignItems="center">
                  Worker Details
                </Stack>
              }
            />
            {
              (inferenceDetails.status === "ACTIVE" || inferenceDetails.status === "INACTIVE") &&
              <Stack>
                {
                  isEditing ?
                    <Stack direction="row" gap={2}>
                      <CustomButton
                        onClick={handleSave}
                        width="100px"
                      >
                        Save
                      </CustomButton>
                      <SecondaryButton
                        onClick={() => {
                          setEditableConfig({
                            "allow_spot_instances": inferenceDetails?.allow_spot_instances,
                            "max_price_per_hour": inferenceDetails?.max_price_per_hour || 0,
                            ...((inferenceDetails?.initial_worker_config
                              && inferenceDetails?.initial_worker_config?.min_workers !== null) &&
                              { "min_workers": inferenceDetails?.initial_worker_config?.min_workers || 0 }),
                            ...(inferenceDetails?.min_throughput_rate &&
                              { "min_throughput_rate": inferenceDetails?.min_throughput_rate || 0 }),
                          })
                          setIsEditing(!isEditing)
                        }}
                      >
                        Cancel
                      </SecondaryButton>
                    </Stack>
                    :
                    <SecondaryButton onClick={() => { setIsEditing(!isEditing) }}>Edit</SecondaryButton>
                }
              </Stack>
            }
          </Stack>
          {
            (inferenceDetails?.initial_worker_config !== null
              && inferenceDetails?.initial_worker_config?.min_workers !== null) &&
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px" width="40%">Minimum Workers</Typography>
              {
                isEditing ?
                  <InputField
                    state={editableConfig.min_workers}
                    setState={(e) => setEditableConfig({ ...editableConfig, "min_workers": e.target.value })}
                    width="65%"
                  />
                  :
                  <Typography color={color.primary} fontSize="15px">
                    {editableConfig.min_workers}
                  </Typography>
              }
            </Stack>
          }
          {
            inferenceDetails?.min_throughput_rate !== null &&
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px" width="40%">Minimum Throughput</Typography>
              {
                isEditing ?
                  <InputField
                    state={editableConfig.min_throughput_rate}
                    setState={(e) =>
                      setEditableConfig({ ...editableConfig, "min_throughput_rate": e.target.value })}
                    width="65%"
                  />
                  :
                  <Typography color={color.primary} fontSize="15px">
                    {editableConfig.min_throughput_rate}
                  </Typography>
              }
            </Stack>
          }
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px" width="40%">Maximum Price/hr</Typography>
            {
              isEditing ?
                <InputField
                  state={editableConfig.max_price_per_hour}
                  setState={(e) => setEditableConfig({ ...editableConfig, "max_price_per_hour": e.target.value })}
                  width="65%"
                />
                :
                <Typography color={color.primary} fontSize="15px">
                  {editableConfig.max_price_per_hour}
                </Typography>
            }
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px" width="40%">Allow Spot Instances</Typography>
            {
              isEditing ?
                <FormControlLabel
                  value={editableConfig.allow_spot_instances}
                  control={
                    <BoxSwitch
                      onChange={(e) =>
                        setEditableConfig({ ...editableConfig, "allow_spot_instances": e.target.checked })}
                    />
                  }
                />
                :
                <Typography color={color.primary} fontSize="15px">
                  {editableConfig.allow_spot_instances.toString()}
                </Typography>
            }
          </Stack >
        </Stack >
      </Stack>
      {
        gpuNodes && gpuNodes.length > 0 &&
        <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
          <Chip
            sx={
              {
                bgcolor: "#FFFFFF",
                color: color.primary,
                width: "25%",
                borderRadius: "6px",
                fontSize: "15px"
              }
            }
            label={
              <Stack gap={1} direction="row" alignItems="center">
                Active Workers (GPU Nodes)
              </Stack>
            } />
          <Stack direction="row" gap={2} flexWrap="wrap">
            {
              gpuNodes?.map((gpu, i) => (
                <Stack
                  key={i}
                  minWidth="30%"
                  borderRadius="12px" justifyContent="center"
                  boxShadow="0px 1px 4px 0px #0000001F" p={2}
                  bgcolor={color.white}
                  spacing={1}
                >
                  <Typography
                    fontFamily="IBMPlexSansSemiBold"
                    align="center"
                  >
                    {gpu.cloud.toUpperCase()}
                  </Typography>
                  <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                    <Typography fontSize="15px" >GPU ID</Typography>
                    <Typography color={color.primary} fontSize="15px">
                      {
                        gpu.id.length > 20 ?
                          gpu.id.slice(0, 20) + "..." : gpu.id
                      }
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                    <Typography fontSize="15px" >GPU Type</Typography>
                    <Typography color={color.primary} fontSize="15px">
                      {gpu.gpu_type || ""}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                    <Typography fontSize="15px" >GPU count</Typography>
                    <Typography color={color.primary} fontSize="15px">
                      {gpu.gpu_count || 0}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                    <Typography fontSize="15px" >Region</Typography>
                    <Typography color={color.primary} fontSize="15px">
                      {gpu.region}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                    <Typography fontSize="15px" >Role</Typography>
                    <Typography color={color.primary} fontSize="15px">
                      {gpu.role}
                    </Typography>
                  </Stack>
                  <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                    <Typography fontSize="15px" >Is Spot Instace</Typography>
                    <Typography color={color.primary} fontSize="15px">
                      {gpu.spot.toString()}
                    </Typography>
                  </Stack>
                </Stack>
              ))
            }
          </Stack>
        </Stack>
      }
    </Stack>
  )
}
