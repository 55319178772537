import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { FormControlLabel, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { NOTIFICATION_OPTIONS } from '../../Configs/SettingsConstants';
import { color } from '../../Styles/Color';
import BoxSwitch from '../UiComponents/BoxSwitch';
import CustomButton from '../UiComponents/CustomButton';
import SecondaryButton from '../UiComponents/SecondaryButton';

const NotificationSection = () => {
  const [isEditing, setIsEditing] = useState(false)
  return (
    <Stack
      borderRadius="12px"
      border="2px solid #E4E4E4"
      pt={1}
      pb={3}
      px={1.5}
      spacing={4}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <Stack border="2px solid #96DBF1" borderRadius="8px" p={0.5}>
            <NotificationsActiveIcon sx={{ color: color.primary }} />
          </Stack>
          <Typography fontSize="20px" color={color.primary}>Notification</Typography>
        </Stack>
        {
          isEditing ?
            <CustomButton onClick={() => setIsEditing(false)} width="100px">Save</CustomButton>
            :
            <SecondaryButton onClick={() => { setIsEditing(!isEditing) }}>Edit</SecondaryButton>
        }
      </Stack>

      {
        Object.entries(NOTIFICATION_OPTIONS).map((entry) => (
          <Stack direction="row" px={2}>
            <Stack width="25%">
              <Typography>{entry[0]}</Typography>
            </Stack>
            <Stack spacing={1}>
              {
                entry[1].map((option) => (
                  <FormControlLabel
                    control={<BoxSwitch disabled={!isEditing} />}
                    label={
                      <Stack mt={1}>
                        <Typography fontSize="17px">{option.text}</Typography>
                        <Typography
                          fontFamily="IBMPlexMonoLight"
                          fontSize="12px"
                          color="#ABABAB"
                        >
                          {option.subText}
                        </Typography>
                      </Stack>
                    }
                  />
                ))
              }
            </Stack>
          </Stack>
        )
        )
      }
    </Stack >
  )
}

export default NotificationSection