import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';

function createData(provider, region, id) {
  return { provider, region, id };
}

export default function ProviderTable({ data, handleDelete }) {

  const rows = (data || [])
    .filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
    .map((d) =>
      createData(d.cloud_provider, d.regions.join(", "), d.id)
    );

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000029",
        maxHeight: "45vh",
      }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="30%">Cloud Provider</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="50%">Region</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: "13px" }}>
                    {row.provider.toUpperCase()}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.region}</TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(row.id)
                      }}
                    />
                  </TableCell>
                </TableRow>
              )) :
              <Stack height="10vh" justifyContent="center" px={2}>
                <Typography color={color.primary} fontSize="15px">No data available</Typography>
              </Stack>
          }
        </TableBody>
      </Table>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="Cloud Provider"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  );
}