import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useState } from 'react';

import { useLocation, useNavigate } from 'react-router';
import { MENU_ITEM_ICONS } from '../../Configs/SideNavBarConstants';
import { color } from '../../Styles/Color';


const NavBarItemDrawer = ({ menuItems }) => {
  const location = useLocation()

  const [menuOpen, setMenuOpen] = useState(location.pathname.split("/")[1].toUpperCase())
  const nav = useNavigate();

  const onMenuClick = (id) => {
    if (menuOpen === id) {
      setMenuOpen(null)
    } else {
      setMenuOpen(id)
    }
  }

  return (
    <nav aria-label='left menu'>
      <List >
        {menuItems.map(({ id, icon, label, items, redirectUrl }) => (
          <React.Fragment key={id}>
            <ListItemButton
              onClick={() => items ? onMenuClick(id) : nav(redirectUrl)}
              sx={{
                maxWidth: '100%',
                borderRadius: "16px",
                padding: "3px 10px",
                "&.Mui-selected": {
                  backgroundColor: color.selectedPrimary,
                },
                "&:hover": {
                  backgroundColor: color.selectedPrimary,
                }
              }}
              selected={location.pathname.includes(redirectUrl)}
            >
              <ListItemIcon sx={{
                '& svg': {
                  color: '#FFFFFF',
                  fontSize: '24px',
                },
              }}>
                {MENU_ITEM_ICONS[id]}
              </ListItemIcon>
              <ListItemText
                primary={label}
                sx={{
                  '& span': {
                    fontFamily: 'IBMPlexSansSemiBold',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                  },
                }}
              />
              {
                items &&
                <>
                  {
                    menuOpen === id ?
                      <ExpandLess sx={{ color: '#FFFFFF' }} />
                      :
                      <ExpandMore sx={{ color: '#FFFFFF' }} />
                  }
                </>
              }
            </ListItemButton>
            {items && (
              <Collapse
                key={`left-menu-collapse-${id}`}
                in={menuOpen === id}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {items.map((item, index) => (
                    <ListItemButton
                      key={`${item.id}-${index}`}
                      onClick={() => nav(item.redirectUrl)}
                      sx={{
                        borderRadius: "16px",
                        "&.Mui-selected": {
                          backgroundColor: color.selectedPrimary,
                        },
                        "&:hover": {
                          backgroundColor: color.selectedPrimary,
                        }
                      }}
                      selected={location.pathname.includes(item.redirectUrl)}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mx: 2,
                          '& svg': {
                            color: '#FFFFFF',
                            fontSize: '15px',
                          },
                        }}
                      >
                        {item.icon()}
                      </ListItemIcon>
                      <Box p="0">
                        <ListItemText
                          primary={item.label}
                          sx={{
                            '& span': {
                              color: '#FFFFFF',
                              fontSize: '15px',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                            },
                          }}
                        />
                      </Box>
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </React.Fragment>
        ))}
      </List>
    </nav>
  )
}

export default NavBarItemDrawer;
