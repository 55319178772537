import { Stack, Typography } from '@mui/material'
import React from 'react'
import { CLOUD_PROVIDERS_OPTIONS } from '../../Configs/CloudProviderConstants'
import { color } from '../../Styles/Color'

const ProviderOptions = ({ isActive, setIsActive }) => {

  const options = [...Object.values(CLOUD_PROVIDERS_OPTIONS)]

  return (
    <Stack gap={1}>
      <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold" >
        Choose Cloud Provider*
      </Typography>
      <Stack direction="row" gap={2} flexWrap='wrap' width="45vw">
        {
          options.map(({ name, icon }) => {
            return <Stack key={name} spacing={1} alignItems="center" onClick={() => setIsActive(name)}>
              <Stack
                width="120px"
                height="120px"
                justifyContent="center"
                alignItems="center"
                border="2px solid"
                borderColor={isActive === name ? color.primary : color.borders}
                borderRadius="8px"
                sx={{
                  cursor: "pointer",
                  boxShadow: isActive === name ? "0px 0px 1px 2px #92CBFF" : ""
                }}
              >
                {icon("90px")}
              </Stack>
              <Typography
                fontFamily="IBMPlexSansSemiBold"
                fontSize="15px"
                color={isActive === name && color.primary}
              >
                {name}
              </Typography>
            </Stack>
          })
        }
      </Stack>
    </Stack>
  )
}

export default ProviderOptions