import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Chip, FormControlLabel, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from 'react';
import { FINE_TUNE_API_KEY_OPTIONS } from '../../../Configs/ConfigureNewJobConstants';
import { CONFIGURE_OPTIONS, MIXED_PRECISION_OPTIONS, QUANTIZATION_OPTIONS, TORCH_DTYPE_OPTIONS } from '../../../Configs/JobConstants';
import { color } from '../../../Styles/Color';
import BoxSwitch from "../../UiComponents/BoxSwitch";
import CustomToggleButton from '../../UiComponents/CustomToggleButton';
import InputField from '../../UiComponents/InputField';

const Configure = ({
  isLoRA, setIsLoRA,
  isQuantization, setIsQuantization,
  isMixedPrecision, setIsMixedPrecision,
  selectedConfig, cloudBurst, register, watch, setValue, errors
}) => {

  const [keyTypeSelected, setKeyTypeSelected] = useState(FINE_TUNE_API_KEY_OPTIONS[0])
  const [apiKey, setApiKey] = useState("")

  useEffect(() => {
    setIsMixedPrecision(true)
    setValue("autotrain_params.mixed_precision", MIXED_PRECISION_OPTIONS[0])
  }, [setIsMixedPrecision, setValue])

  return (
    <Stack spacing={2}>
      <Typography fontSize="17px" mt={2}>
        Auto-Train Parameters
      </Typography>
      <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "25%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <DeviceHubIcon sx={{ color: color.primary, fontSize: "16px" }} />
              Training Configurations
            </Stack>
          } />
        <Stack gap={2} direction="row">
          <Stack spacing={1} width="30%">
            <Typography fontSize="15px">Epochs</Typography>
            <InputField type="number"
              // state={epochs} setState={(e) => setEpochs(e.target.value)} 
              width="100%"
              register={register} field="autotrain_params.epochs" watch={watch}
            />
          </Stack>
          <Stack spacing={1} width="30%">
            <Typography fontSize="15px">Learning Rate</Typography>
            <InputField type="number"
              // state={learningRate} setState={(e) => setLearningRate(e.target.value)} 
              register={register} field="autotrain_params.lr"
              width="100%" watch={watch} />
          </Stack>
          <Stack spacing={1} width="30%">
            <Typography fontSize="15px">Batch Size</Typography>
            <InputField type="number"
              // state={learningRate} setState={(e) => setLearningRate(e.target.value)} 
              register={register} field="autotrain_params.batch_size"
              width="100%" watch={watch} />
          </Stack>
        </Stack>
        <Stack gap={10} direction="row" p={2}>
          <FormControlLabel
            value={watch("autotrain_params.disable_gradient_checkpointing")}
            control={
              <BoxSwitch
                onChange={(e) => setValue("autotrain_params.disable_gradient_checkpointing", e.target.checked)}
              />
            }
            label={
              <Stack direction="row" alignItems="center">
                <Typography fontSize="17px">Disable Gradient Checkpointing</Typography>
              </Stack>
            }
          />
          <FormControlLabel
            value={watch("autotrain_params.use_flash_attention_2")}
            control={
              <BoxSwitch onChange={(e) => setValue("autotrain_params.use_flash_attention_2", e.target.checked)}
              />
            }
            label={
              <Stack direction="row" alignItems="center">
                <Typography fontSize="17px">Use FlashAttention2</Typography>
              </Stack >
            }
          />
        </Stack>
        <Stack gap={4} direction="row">
          <Stack spacing={1} width="45%">
            <Typography fontSize="15px">Gradient Accumulation Steps</Typography>
            <InputField type="number"
              // state={epochs} setState={(e) => setEpochs(e.target.value)} 
              width="100%"
              register={register} field="autotrain_params.gradient_accumulation" watch={watch}
            />
          </Stack>
          <Stack spacing={1} width="45%">
            <Typography fontSize="15px">Model Max Length</Typography>
            <InputField type="number"
              // state={loRASettings.dropout}
              // setState={(e) => setLoRaSettings(prev => ({ ...prev, dropout: e.target.value }))}
              register={register} field="autotrain_params.model_max_length" watch={watch}
              width="100%" />
          </Stack>
        </Stack>
        <Stack gap={4} direction="row">
          <Stack spacing={1} width="45%">
            <Typography fontSize="15px">Block Size</Typography>
            <InputField type="number"
              // state={loRASettings.r}
              // setState={(e) => setLoRaSettings(prev => ({ ...prev, r: e.target.value }))}
              register={register} field="autotrain_params.block_size" watch={watch}
              width="100%" />
          </Stack>
          <Stack spacing={1} width="45%">
            <Typography fontSize="15px">Seed</Typography>
            <InputField type="number"
              // state={loRASettings.alpha}
              // setState={(e) => setLoRaSettings(prev => ({ ...prev, alpha: e.target.value }))}
              register={register} field="autotrain_params.seed" watch={watch}
              width="100%" />
          </Stack>
        </Stack>
      </Stack >
      <Stack py={2} px={3} spacing={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "30%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <DeviceHubIcon sx={{ color: color.primary, fontSize: "16px" }} />
              Compute Optimization Configurations
            </Stack>
          } />
        {
          (cloudBurst || selectedConfig === CONFIGURE_OPTIONS[0]) &&
          <FormControlLabel sx={{ py: 1 }}
            value={watch("use_spot")}
            control={
              <BoxSwitch
                onChange={(e) => {
                  setValue("use_spot", e.target.checked)
                  if (e.target.checked === true) {
                    setIsLoRA(true)
                  }
                }}
              />
            }
            label={
              <Stack direction="row" alignItems="center">
                <Typography fontSize="17px" mx={1}>Use Spot Instances (subject to availability)</Typography>
                <Tooltip title="Information">
                  <InfoOutlinedIcon sx={{
                    color: '#ABABAB',
                    fontSize: '20px'
                  }} />
                </Tooltip>
              </Stack >
            }
          />
        }
        <FormControlLabel sx={{ py: 1 }}
          value={isLoRA}
          control={
            <BoxSwitch
              value={isLoRA}
              disabled={watch('use_spot') === true ? true : false}
              onChange={(e) => setIsLoRA(e.target.checked)}
            />
          }
          label={<Stack direction="row" alignItems="center">
            <Typography fontSize="17px" mx={1}>LoRA</Typography>
            <Tooltip title="Information">
              <InfoOutlinedIcon sx={{
                color: '#ABABAB',
                fontSize: '20px'
              }} />
            </Tooltip>
          </Stack >}
        />
        {
          isLoRA && (
            <>
              <Typography fontSize="15px">LoRA Settings</Typography>
              <Stack gap={2} direction="row">
                <Stack spacing={1} width="30%">
                  <Typography fontSize="15px">r</Typography>
                  <InputField type="number"
                    // state={loRASettings.r}
                    // setState={(e) => setLoRaSettings(prev => ({ ...prev, r: e.target.value }))}
                    register={register} field="autotrain_params.lora_r" watch={watch}
                    width="100%" />
                </Stack>
                <Stack spacing={1} width="30%">
                  <Typography fontSize="15px">Alpha</Typography>
                  <InputField type="number"
                    // state={loRASettings.alpha}
                    // setState={(e) => setLoRaSettings(prev => ({ ...prev, alpha: e.target.value }))}
                    register={register} field="autotrain_params.lora_alpha" watch={watch}
                    width="100%" />
                </Stack>
                <Stack spacing={1} width="30%">
                  <Typography fontSize="15px">Dropout</Typography>
                  <InputField type="number"
                    // state={loRASettings.dropout}
                    // setState={(e) => setLoRaSettings(prev => ({ ...prev, dropout: e.target.value }))}
                    register={register} field="autotrain_params.lora_dropout" watch={watch}
                    width="100%" />
                </Stack>
              </Stack>
            </>
          )
        }

        <Stack gap={4} direction="row" p={1.5}>
          <FormControlLabel
            value={isQuantization}
            control={
              <BoxSwitch
                onChange={(e) => {
                  setIsQuantization(e.target.checked)
                  if (e.target.checked === true) {
                    setValue("autotrain_params.quantization", QUANTIZATION_OPTIONS[0])
                  } else {
                    setValue("autotrain_params.quantization", null)
                  }
                }}
              />
            }
            label={<Stack direction="row" alignItems="center">
              <Typography fontSize="17px" mx={1}>Quantization</Typography>
              <Tooltip title="Information">
                <InfoOutlinedIcon sx={{
                  color: '#ABABAB',
                  fontSize: '20px'
                }} />
              </Tooltip>
            </Stack >}
          />
          <FormControlLabel
            value={isMixedPrecision}
            control={
              <BoxSwitch
                onChange={(e) => {
                  setIsMixedPrecision(e.target.checked)
                  if (e.target.checked === true) {
                    setValue("autotrain_params.mixed_precision", MIXED_PRECISION_OPTIONS[0])
                  } else {
                    setValue("autotrain_params.mixed_precision", null)
                  }
                }}
              />
            }
            label={<Stack direction="row" alignItems="center">
              <Typography fontSize="17px" mx={1}>Mixed Precision</Typography>
              <Tooltip title="Information">
                <InfoOutlinedIcon sx={{
                  color: '#ABABAB',
                  fontSize: '20px'
                }} />
              </Tooltip>
            </Stack >}
          />
        </Stack>
        {
          isQuantization &&
          <Stack spacing={2}>
            <Typography fontSize="15px">Quantization Type</Typography>
            <Select
              displayEmpty
              size='small'
              sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
              value={watch("autotrain_params.quantization")}
              onChange={(e) => setValue("autotrain_params.quantization", e.target.value)}
            >
              <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
                Choose one
              </MenuItem>
              {
                QUANTIZATION_OPTIONS.map((model, idx) => (
                  <MenuItem value={model} key={idx} sx={{ fontSize: "15px" }}>
                    {model}
                  </MenuItem>
                ))
              }
            </Select>
          </Stack>
        }
        {
          isMixedPrecision &&
          <Stack spacing={2}>
            <Typography fontSize="15px">Mixed Precision Type</Typography>
            <Select
              displayEmpty
              size='small'
              sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
              value={watch("autotrain_params.mixed_precision")}
              onChange={(e) => setValue("autotrain_params.mixed_precision", e.target.value)}
            >
              <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
                Choose one
              </MenuItem>
              {
                MIXED_PRECISION_OPTIONS.map((model, idx) => (
                  <MenuItem value={model} key={idx} sx={{ fontSize: "15px" }}>
                    {model}
                  </MenuItem>
                ))
              }
            </Select>
          </Stack>
        }
        <Stack spacing={1}>
          <Typography fontSize="15px">Torch dType</Typography>
          <Select
            displayEmpty
            size='small'
            sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
            value={watch("autotrain_params.torch_dtype")}
            onChange={(e) => setValue("autotrain_params.torch_dtype", e.target.value)}
          >
            {
              TORCH_DTYPE_OPTIONS.map((model, idx) => (
                <MenuItem value={model} key={idx} sx={{ fontSize: "15px" }}>
                  {model}
                </MenuItem>
              ))
            }
          </Select>
        </Stack>
      </Stack >
      <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "25%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <DeviceHubIcon sx={{ color: color.primary, fontSize: "16px" }} />
              Dataset Configurations
            </Stack>
          } />
        <Stack gap={4} direction="row">
          <Stack spacing={1} width="45%">
            <Typography fontSize="15px">Train Subset</Typography>
            <InputField
              // state={loRASettings.r}
              // setState={(e) => setLoRaSettings(prev => ({ ...prev, r: e.target.value }))}
              register={register} field="autotrain_params.train_subset" watch={watch}
              width="100%" />
          </Stack>
          <Stack spacing={1} width="45%">
            <Typography fontSize="15px">Text Column</Typography>
            <InputField
              // state={loRASettings.alpha}
              // setState={(e) => setLoRaSettings(prev => ({ ...prev, alpha: e.target.value }))}
              register={register} field="autotrain_params.text_column" watch={watch}
              width="100%" />
          </Stack>
        </Stack>
      </Stack >
      <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "25%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <DeviceHubIcon sx={{ color: color.primary, fontSize: "16px" }} />
              Experiment Tracking
            </Stack>
          } />
        <CustomToggleButton options={FINE_TUNE_API_KEY_OPTIONS}
          selected={keyTypeSelected} setSelected={setKeyTypeSelected} />
        {
          keyTypeSelected === FINE_TUNE_API_KEY_OPTIONS[0] ?
            <Stack spacing={1} >
              <Typography fontSize="15px">WANDB API Key</Typography>
              <InputField placeholder="xxxxxxxxxxxxxxxxxxxx"
                // state={apiKey}
                //   setState={(e) => setApiKey(e.target.value)}
                register={register} field="wandb_key"
                width="70%" watch={watch}
              />
            </Stack>
            :
            <Stack spacing={1} >
              <Typography fontSize="15px">COMETML API Key</Typography>
              <InputField placeholder="xxxxxxxxxxxxxxxxxxxx"
                state={apiKey}
                setState={(e) => setApiKey(e.target.value)}
                // register={register} field="wandb_key"
                width="70%"
              // watch={watch}
              />
            </Stack>
        }
      </Stack>
    </Stack>
  )
}

export default Configure