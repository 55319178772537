import { Stack, Typography } from '@mui/material';
import { VM_STORAGE_TYPE_OPTIONS } from '../../Configs/VirtualMountConstants';
import { color } from '../../Styles/Color';

const ChooseStorageType = ({ isActive, setIsActive }) => {

  return (
    <Stack gap={1} >
      <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold" >
        Virtual Machine Cloud
      </Typography>
      <Stack direction="row" gap={2} flexWrap='wrap' width="50vw">
        {
          VM_STORAGE_TYPE_OPTIONS.map(({ name, icon }) => {
            return <Stack key={name}
              width="125px" spacing={1} alignItems="center"
              onClick={() =>
                setIsActive(name)
              }
            >
              <Stack
                width="120px"
                height="120px"
                justifyContent="center"
                alignItems="center"
                border="2px solid"
                borderColor={isActive === name ? color.primary : color.borders}
                borderRadius="8px"
                sx={{
                  cursor: "pointer",
                  boxShadow: isActive === name ? "0px 0px 1px 2px #92CBFF" : ""
                }}
              >
                {icon("60px")}
              </Stack>
              <Typography
                fontFamily="IBMPlexSansSemiBold"
                fontSize="15px"
                color={isActive === name && color.primary}
                textAlign="center"
              >
                {name}
              </Typography>
            </Stack>
          })
        }
      </Stack>
    </Stack>
  )
}

export default ChooseStorageType