import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Chip, Stack, Typography } from '@mui/material';
import { color } from '../../Styles/Color';
import InputField from '../UiComponents/InputField';

export const Pricing = ({
  // price, setPrice
  register, watch
}) => {

  return (
    <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Chip
        sx={
          {
            bgcolor: "#FFFFFF",
            color: color.primary,
            width: "25%",
            borderRadius: "6px",
            fontSize: "15px"
          }
        }
        label={
          <Stack gap={1} direction="row" alignItems="center">
            <AttachMoneyIcon sx={{ color: color.primary, fontSize: "16px" }} />
            Pricing
          </Stack>
        } />
      <Stack gap={1} >
        <Typography fontSize="15px">Maximum Price/hour</Typography>
        <Stack direction="row" alignItems="center" gap={1}>
          <Stack
            color={color.white}
            border="1px solid #92CBFF"
            bgcolor={color.primary}
            borderRadius="8px"
            px={1.5} py={1}
            fontSize="15px"
          >
            $
          </Stack>
          <InputField
            // state={price} setState={(e) => setPrice(e.target.value)} 
            watch={watch}
            register={register} field="max_price_per_hour"
            width="70%" />
        </Stack>
      </Stack>
    </Stack >
  )
}
