import KeyIcon from '@mui/icons-material/Key';
import { Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAddAccessKeysMutation, useDeleteAccessKeysMutation } from '../../Services/accessKeysApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import Loader from "../UiComponents/Loader";
import { CreateKeyModal } from './CreateKeyModal';

export const AccessKeys = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false)

  const [deleteKey, { isLoading }] = useDeleteAccessKeysMutation()
  const [addKey, { data: accessKey, isLoading: isAdding, isSuccess }] = useAddAccessKeysMutation()

  useEffect(() => {
    if (isSuccess) {
      setIsOpen(true)
    }
  }, [isSuccess])

  return (
    <Stack
      borderRadius="12px"
      border="2px solid #E4E4E4"
      py={1}
      px={1.5}
      spacing={2}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" alignItems="center" gap={2}>
          <Stack border="2px solid #96DBF1" borderRadius="8px" p={0.5}>
            <KeyIcon sx={{ color: color.primary }} />
          </Stack>
          <Typography fontSize="20px" color={color.primary}>Access Key</Typography>
        </Stack>
        {
          data ?
            <CustomButton onClick={() => deleteKey(data[0].access_key_id)} width="20%" color="#FF6B6A">
              {
                isLoading ?
                  <Loader /> :
                  "Delete Access Key"
              }
            </CustomButton>
            :
            <CustomButton onClick={() => addKey()} width="20%">
              {
                isAdding ?
                  <Loader /> :
                  "Create Access Key"
              }
            </CustomButton>
        }
      </Stack>
      {
        data &&
        <Stack spacing={2} p={2}>
          <Stack direction="row" gap={2}>
            <Typography fontSize="15px" width="20%">Access Key ID</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography fontSize="15px">{data[0].access_key_id}</Typography>
          </Stack>
          <Stack direction="row" gap={2}>
            <Typography fontSize="15px" width="20%">Access Key Secret</Typography>
            <Divider orientation="vertical" flexItem />
            <Typography fontSize="15px">{"*".repeat(8)}</Typography>
          </Stack>
        </Stack>
      }
      {
        accessKey &&
        <CreateKeyModal isOpen={isOpen} handleClose={() => setIsOpen(false)} data={accessKey} />
      }
    </Stack >
  )
}
