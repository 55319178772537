import ClearIcon from '@mui/icons-material/Clear';
import DvrIcon from '@mui/icons-material/Dvr';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Stack, Typography } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useAddUserOnPremNodeMutation } from '../../Services/nodeApi';
import { color } from '../../Styles/Color';
import BoxSwitch from '../UiComponents/BoxSwitch';
import CustomButton from '../UiComponents/CustomButton';
import InputField from '../UiComponents/InputField';
import Loader from '../UiComponents/Loader';

const AddNodeModal = ({ isOpen, setIsOpen }) => {
  const initialFormState = Object.freeze({
    ip: "",
    port: "",
    username: "root",
    ssh_private_key: "",
    role: ""
  })

  const [formValues, setFormValues] = useState(initialFormState)

  const [handleSubmit, { isLoading, isSuccess }] = useAddUserOnPremNodeMutation();

  const handleClose = useCallback(() => {
    setFormValues(initialFormState)
    setIsOpen(false)
  }, [initialFormState, setIsOpen])

  useEffect(() => {
    if (isSuccess) {
      setFormValues(initialFormState)
      handleClose()
    }
  }, [isSuccess, handleClose, initialFormState])

  const handleAdd = () => {
    const encodedFormValues = {
      ...formValues,
      ssh_private_key: btoa(formValues.ssh_private_key)
    }
    handleSubmit(encodedFormValues)
  }

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose}
      PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <DvrIcon fontSize='20px' sx={{ color: color.primary }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Add On-Prem Nodes</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "50vh",
        width: "55vw", p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        {
          isLoading ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            <Stack gap={2}>
              <Stack gap={1} >
                <Typography fontSize="15px">Node IP</Typography>
                <InputField state={formValues.ip}
                  placeholder="Enter IP/DNS address of the on-prem node"
                  setState={(e) => setFormValues((prev) => ({ ...prev, ip: e.target.value }))}
                />
              </Stack>
              <Stack gap={1} >
                <Typography fontSize="15px">Port</Typography>
                <InputField state={formValues.port}
                  placeholder="Enter access port of the on-prem node"
                  setState={(e) => setFormValues((prev) => ({ ...prev, port: e.target.value }))}
                />
              </Stack>
              <Stack gap={1} >
                <Typography fontSize="15px">Username</Typography>
                <InputField state={formValues.username}
                  placeholder="Enter username for the GPU node"
                  setState={(e) => setFormValues((prev) => ({ ...prev, username: e.target.value }))}
                />
              </Stack>
              <Stack gap={1} >
                <Typography fontSize="15px">SSH Private Key</Typography>
                <InputField state={formValues.ssh_private_key}
                  placeholder="Enter the SSH key for the GPU node"
                  setState={(e) => setFormValues((prev) => ({ ...prev, ssh_private_key: e.target.value }))}
                />
              </Stack>
              {/* <Stack spacing={1}>
                <Typography fontSize="1rem" >
                  Role
                </Typography>
                <Select
                  size='small'
                  value={formValues.role}
                  onChange={handleChange}
                  input={<OutlinedInput placeholder="Choose role of On-prem Node" />}
                  sx={{ bgcolor: color.white, borderRadius: "8px", fontSize: "15px" }}

                >
                  {ON_PREM_NODE_TYPE_OPTIONS.map((name, idx) => (
                    <MenuItem
                      key={idx}
                      value={name}
                      sx={{ fontSize: "15px" }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </Stack> */}
              <FormControlLabel sx={{ px: 2, pt: 1 }}
                control={
                  <BoxSwitch
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        setFormValues((prev) => ({ ...prev, role: "INFERENCE_CONTROLLER" }))
                      } else {
                        setFormValues((prev) => ({ ...prev, role: "INFERENCE_WORKER" }))
                      }
                    }
                    }
                  />
                }
                label={<Typography fontSize="17px">Controller Only</Typography>}
              />
            </Stack>
        }
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={handleAdd}
          width="10%"
        >
          Add
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}

export default AddNodeModal