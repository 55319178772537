import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import { Stack, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import { APIGatewayCongif } from '../Components/LLMGatewayComponents/APIGatewayConfig';
import { LLMGatewayConfig } from '../Components/LLMGatewayComponents/LLMGatewayConfig';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { RequiredHeader } from '../Components/UiComponents/RequiredHeader';
import { PAGE_ROUTES } from '../Configs/Routes';
import { useGetUserCloudsQuery } from '../Services/cloudProviderApi';
import { useAddLLMGatewayMutation, useGetLLMProvidersQuery } from '../Services/llmgatewayApi';
import { color } from '../Styles/Color';

const initialValue = {
  "name": "",
  "gateway_cloud_config": {
    "name": "AWS",
    "region": ""
  },
  "llm_apis": [
    "ANYSCALE"
  ],
  "has_semantic_caching": true,
  "has_pii_anon": true,
  "has_prompt_inject_check": true,
  "num_reties": 5
}

export const CreateNewLLMGateway = () => {

  const nav = useNavigate()

  const { register, formState: { errors }, watch, handleSubmit, setValue } =
    useForm({
      defaultValues: initialValue
    })

  const [submit, { isLoading: isSubmitting, isSuccess }] = useAddLLMGatewayMutation()


  const handleValidation = () => {
    handleSubmit(handleStart)()
  }

  const handleStart = (data) => {
    submit(data)
  }

  const { data: cloudProviderOptions, isLoading: isFetchingClouds } = useGetUserCloudsQuery()
  const { data: llmProviders, isLoading, isSuccess: isProvidersFetched } = useGetLLMProvidersQuery()

  useEffect(() => {
    if (isProvidersFetched) {
      setValue("llm_apis", llmProviders.map(provider => provider.provider))
    }
  }, [isProvidersFetched, llmProviders, setValue])


  if (isFetchingClouds || isSubmitting || isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  if (isSuccess) {
    nav(PAGE_ROUTES.llmGateways)
  }

  return (
    <Stack gap={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" pb={2}>
        New LLM Gateway
      </Typography>
      <Stack direction="row" gap={2} alignItems="center">
        <RequiredHeader>
          LLM Gateway Name
        </RequiredHeader>
        <TextField
          size='small'
          variant="standard"
          placeholder='Enter gateway name'
          sx={{
            fontFamily: "IBMPlexSansSemiBold",
          }}
          InputProps={{
            style: {
              fontFamily: "IBMPlexSansSemiBold", fontSize: "15px",
              color: color.primary
            },
          }}
          {...register("name", { required: { value: true, message: "This field is required" } })}
          error={errors?.name ? true : false}
          helperText={errors?.name?.message}
        />
        <EditIcon sx={{ color: color.primary, fontSize: "16px" }} />
      </Stack>
      <Typography fontSize="17px" mt={2}>
        LLM Gateway Configurations
      </Typography>
      <APIGatewayCongif
        watch={watch}
        setValue={setValue}
        userClouds={cloudProviderOptions}
      />
      <LLMGatewayConfig
        watch={watch}
        setValue={setValue}
        register={register}
        llmProviders={llmProviders}
      />
      <Stack alignItems="end" >
        <CustomButton width="15%" onClick={handleValidation}>
          <Stack fontSize="15px" direction="row" alignItems="center">
            Start
            <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
          </Stack>
        </CustomButton>
      </Stack >
    </Stack>
  )
}
