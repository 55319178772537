import { Button } from '@mui/material'
import React from 'react'
import { color } from '../../Styles/Color'

const SecondaryButton = ({ children, onClick }) => {
  return (
    <Button
      onClick={onClick}
      sx={{
        boxShadow: "0px 0px 4px 0px #0000001F",
        border: `1px solid ${color.borders}`,
        color: color.primary,
        fontSize: "15px",
        px: 4,
        borderRadius: "8px",
        height: "20px",
        py: 2
      }}
    >
      {children}
    </Button >
  )
}

export default SecondaryButton