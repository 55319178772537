import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Chip, Stack, Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import InferenceList from '../Components/InferenceViewComponents/InferenceList';
// import LLMGatewayList from '../Components/LLMGatewayComponents/LLMGatewayList';
import JobsList from "../Components/TrainingViewComponents/JobsList";
import Loader from "../Components/UiComponents/Loader";
import { useGetUserInferencesQuery } from '../Services/inferenceApi';
import { useGetJobsQuery } from "../Services/jobsApi";
// import { useGetLLMGatewaysQuery } from '../Services/llmgatewayApi';
import { color } from '../Styles/Color';

const DashboardView = () => {

    const platformBearer = useSelector((state) => state.userState.platformBearer);

    const { data: fetchedData, isLoading, isSuccess, } =
        useGetJobsQuery({}, { skip: !platformBearer });

    const { data: inferenceData, isLoading: isFetchingInferenceJobs } =
        useGetUserInferencesQuery({}, { skip: !platformBearer });

    // const { data: llmGateway, isLoading: isFetchingLLMGateways } =
    //     useGetLLMGatewaysQuery({}, { skip: !platformBearer });

    if (isLoading || isFetchingInferenceJobs
        // || isFetchingLLMGateways
    ) {
        return <Loader />
    }

    let runningFineTuneJobs = []
    let runningInferenceJobs = []
    // let activeLLMGateways = []

    if (isSuccess) {
        runningFineTuneJobs = (fetchedData || [])?.filter(job => job.spec.type === "FINETUNING"
            && job.status === "RUNNING")

        runningInferenceJobs = (inferenceData || [])?.filter(job => job.status === "ACTIVE")
        // activeLLMGateways = (llmGateway || [])?.filter(job => job.status === "ACTIVE")
    }

    return (
        <Stack
            gap={3}
            overflow="auto"
            sx={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                }
            }}
        >
            <Chip
                sx={
                    {
                        bgcolor: "#F5F5F5",
                        color: color.primary,
                        width: "25%",
                        borderRadius: "6px",
                        fontSize: "17px",
                    }
                }
                label={
                    <Stack gap={1} direction="row" alignItems="center" p={1}>
                        <PlayArrowIcon sx={{ color: color.primary, fontSize: "20px" }} />
                        Running
                    </Stack>
                } />
            <Stack gap={1}>
                <Typography fontFamily="IBMPlexSansSemiBold" fontSize="20px" pb={2}>
                    Fine-Tuning
                </Typography>
                {
                    runningFineTuneJobs.length > 0
                        ? <JobsList data={runningFineTuneJobs} height={300} />
                        : <Stack height="15vh">
                            <Typography color={color.primary} fontSize="15px">No Running Jobs</Typography>
                        </Stack>
                }
            </Stack>
            <Stack gap={1} >
                <Typography fontFamily="IBMPlexSansSemiBold" fontSize="20px" pb={2}>
                    Inference
                </Typography>
                {
                    runningInferenceJobs.length > 0
                        ? <InferenceList data={runningInferenceJobs} height={300} />
                        : <Stack height="15vh">
                            <Typography color={color.primary} fontSize="15px">No Active Inference</Typography>
                        </Stack>
                }
            </Stack >
            {/* <Stack gap={1} >
                <Typography fontFamily="IBMPlexSansSemiBold" fontSize="20px" pb={2}>
                    LLM Gateway
                </Typography>
                {
                    activeLLMGateways.length > 0
                        ? <LLMGatewayList data={activeLLMGateways} height={300} />
                        : <Stack height="15vh">
                            <Typography color={color.primary} fontSize="15px">No Active LLM gateways</Typography>
                        </Stack>
                }
            </Stack > */}
        </Stack >
    );
};

export default DashboardView;
