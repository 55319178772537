import { Stack, Typography } from '@mui/material'
import { AccessKeys } from '../Components/SettingsComponents/AccessKeys'
import ConnectSlack from '../Components/SettingsComponents/ConnectSlack'
import NotificationSection from '../Components/SettingsComponents/NotificationSection'
import Profile from '../Components/SettingsComponents/Profile'
import Loader from '../Components/UiComponents/Loader'
import { useGetAccessKeysQuery } from '../Services/accessKeysApi'

const SettingsView = () => {
  const { data, isLoading } = useGetAccessKeysQuery()

  if (isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }


  return (
    <Stack sx={{
      maxHeight: '100%',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        display: 'none',
      }
    }}>
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" pb={2}>Settings</Typography>
      <Stack spacing={1}>
        <Profile />
        <AccessKeys data={data} />
        <NotificationSection />
        <ConnectSlack />
      </Stack>
    </Stack>
  )
}

export default SettingsView