import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';

const Header = ({ handleAdd }) => {
  return (
    <Stack gap={1}>
      <Typography fontFamily="IBMPlexSansBold" fontSize="25px" pb={2}>Team</Typography>
      <Stack gap={4} direction="row">
        <OutlinedInput
          size='small'
          fullWidth
          border={`1px solid ${color.borders}`}
          sx={{
            fontSize: '15px',
            borderRadius: "8px",
            boxShadow: "0px 0px 4px 0px #00000029"
          }}
          placeholder='Search team members'
          startAdornment={<InputAdornment position="start">
            <SearchIcon fontSize='small' />
          </InputAdornment>}
        />
        <CustomButton height="42px" width="50%" onClick={handleAdd}>
          <Stack direction="row" alignItems="center" height="32px" gap={2}>
            <PersonAddIcon />
            <Typography fontSize="15px">Add New Members</Typography>
          </Stack>
        </CustomButton>
      </Stack>
    </Stack >
  )
}

export default Header