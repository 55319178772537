import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Stack } from '@mui/material';
// import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import * as React from 'react';

export default function NavigationTabs({ panels, tabs, value, setValue }) {

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList onChange={handleChange} sx={{ minHeight: "26px" }}>
        {
          tabs.map((label, i) =>
            <Tab key={i} label={label} value={String(i)}
              sx={{ fontSize: '15px', p: 0, mr: 1.5, mt: 1, minHeight: 0, minWidth: 0 }} />
          )
        }
      </TabList>
      <Stack
        overflow="auto"
        sx={{
          pt: 1,
          '&::-webkit-scrollbar': {
            display: 'none',
          }
        }}
      >
        {
          panels.map((tab, i) =>
            <TabPanel sx={{ p: 0, my: 1 }} key={i} value={String(i)}>{tab}</TabPanel>
          )
        }
      </Stack>
    </TabContext>
  );
}