import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';

function createData(name, source, destination, filter, prefetch, id) {
  source = source.length > 15 ? source.substring(0, 15) + "..." : source;
  destination = destination.length > 20 ? destination.substring(0, 20) + "..." : destination;
  return { name, source, destination, filter, prefetch, id };
}

export default function VMList({ data, handleDelete }) {

  const rows = (data || []).map((d) =>
    createData(d.name, d.src, d.dest, d.filter || null, d.prefetch, d.id))

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000029",
        maxHeight: "75vh"
      }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Name</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="25%" >Source</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="25%">Destination</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%">Filter</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%">Prefetch</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  hover
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: "13px" }}>
                    {row.name}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.source}</TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.destination}</TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.filter}</TableCell>
                  <TableCell align='center'>
                    {
                      row.prefetch ?
                        <CheckIcon sx={{ fontSize: "20px", color: "#2C974A" }} /> :
                        <ClearIcon sx={{ fontSize: "20px", color: "#FF6B6A" }} />
                    }
                  </TableCell>
                  <TableCell align='center'>
                    <DeleteButton
                      onClick={() => {
                        setIsDeleteModalOpen(true)
                        setDeletingId(row.id)
                      }
                      }
                    />
                  </TableCell>
                </TableRow>
              )) :
              <Stack height="10vh" justifyContent="center" px={2}>
                <Typography color={color.primary} fontSize="15px">No data available</Typography>
              </Stack>
          }
        </TableBody>
      </Table>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="Virtual Mount"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </TableContainer>
  );
}