import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { ReactComponent as SlackIcon } from "../../Assets/Images/SlackIcon.svg"
import CustomButton from '../UiComponents/CustomButton'

const ConnectSlack = () => {
  const sgUser = useSelector((state) => state.userState.sgUser)

  return (
    <Stack
      borderRadius="12px"
      border="2px solid #E4E4E4"
      py={1}
      px={1.5}
      spacing={4}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Stack border="2px solid #96DBF1" borderRadius="8px" p={0.5}>
            <SlackIcon />
          </Stack>
          <Typography fontSize="17px">Connect to Slack</Typography>
        </Stack>
        {
          sgUser?.user_metadata?.sg_slack_webhook_url
            ?
            <CustomButton color="#FF6B6A" width="150px" >Disconnect</CustomButton>
            :
            <Link to={process.env.REACT_APP_SLACK_APP_URL}>
              <CustomButton width="150px">Connect</CustomButton>
            </Link>
        }
      </Stack>
    </Stack>
  )
}

export default ConnectSlack