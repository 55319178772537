import { Box, Chip, FormControlLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import { ONPREMISE_CONFIG_TYPE_TABS } from '../../Configs/NewJobConstants'
import { color } from '../../Styles/Color'
import BoxSwitch from '../UiComponents/BoxSwitch'
import InputField from '../UiComponents/InputField'
// import NavigationTabs from '../UiComponents/NavigationTabs'
import { CloudConfig } from './CloudConfig'

export const OnPremiseConfig = ({
  gpuOptions, gpuTypeOptions, cloudProvider, setCloudProvider, region, setRegion, cloudProviderOptions, regionOptions, instanceOptions, register, watch, setValue,
  cloudGpuWorkerConfig, setCloudGPUWorkerConfig

  // setSpotInstance,  gpuNodes, setGpuNodes, gputype, setGputype,  minWorker, setMinWorker, allowOtherGPU, setAllowOtherGPU, useSpotInstace,  setCloudBurst, cloudBurst,
  // setGPUNodeType, gpuNodeType, numberOfGPUs, setNumberOfGPUs, instanceType, setInstancetype, 
  // gpuPerNode, setGPUPerNode
}) => {

  // const [gpuTypes, setGPUTypes] = useState([])
  // const [onPremType, setOnPremType] = useState("0")


  // useEffect(() => {
  //   setGPUTypes(gpuOptions.filter(gpu => gpu?.role === "WORKER").map(gpu => gpu.gpu_type))
  // }, [gpuOptions])

  // const ONPREMISE_CONFIG_TYPE_PANELS = [
  //   <Stack spacing={1}>
  //     <Typography fontSize="15px">Choose GPU Nodes</Typography>
  //     <Select
  //       size='small'
  //       multiple
  //       displayEmpty
  //       value={watch("initial_worker_config.gpuNodes") || []}
  //       onChange={(e) => setValue("initial_worker_config.gpuNodes", e.target.value)}
  //       input={<OutlinedInput />}
  //       renderValue={(selected, idx) => (
  //         <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
  //           {
  //             selected.length === 0 ? <Typography fontSize="15px">Choose GPU Nodes</Typography>
  //               :
  //               selected?.map((value) => (
  //                 <Chip key={value} sx={{
  //                   bgcolor: color.lightBlue,
  //                   borderRadius: "6px",
  //                   fontSize: "12px",
  //                 }} label={value} />
  //               ))
  //           }
  //         </Box>
  //       )}
  //       sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
  //     >
  //       {
  //         gpuOptions?.filter(gpu => gpu?.role === "WORKER").map((gpu, idx) => (
  //           <MenuItem
  //             key={idx}
  //             value={gpu.ip}
  //             sx={{ fontSize: "15px" }}
  //           >
  //             {gpu.ip}{gpu.gpu_count > 0 && " - " + gpu.gpu_count + "x" + gpu.gpu_type}
  //           </MenuItem>
  //         ))
  //       }
  //     </Select>
  //     {
  //       watch("initial_worker_config.gpuNodes")?.length > 0 &&
  //       <Stack gap={1} >
  //         <Typography fontSize="15px">GPU Type</Typography>
  //         <InputField
  //           state={
  //             watch("initial_worker_config.gpuNodes")
  //               .reduce((acc, node) => acc + gpuOptions.find(gpu => gpu.ip === node)?.gpu_type, "")}
  //           setState={(e) => { }} width="70%" />
  //       </Stack>
  //     }
  //   </Stack>,
  //   <Stack spacing={1} >
  //     <Typography fontSize="15px">Choose GPU Type</Typography>
  //     <Select
  //       size='small'
  //       multiple
  //       displayEmpty
  //       value={watch("initial_worker_config.gpuNodeType") || []}
  //       onChange={(e) => setValue("initial_worker_config.gpuNodeType", e.target.value)}
  //       input={<OutlinedInput />}
  //       renderValue={(selected, idx) => (
  //         <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
  //           {
  //             selected.length === 0 ? <Typography fontSize="15px">Choose GPU type</Typography> :
  //               selected.map((value) => (
  //                 <Chip key={value} sx={{
  //                   bgcolor: color.lightBlue,
  //                   borderRadius: "6px",
  //                   fontSize: "12px",
  //                 }} label={value} />
  //               ))
  //           }
  //         </Box>
  //       )}
  //       sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
  //     >
  //       {
  //         gpuTypes?.map((gpu, idx) => (
  //           <MenuItem
  //             key={idx}
  //             value={gpu}
  //             sx={{ fontSize: "15px" }}
  //           >
  //             {gpu}
  //           </MenuItem>
  //         ))}
  //     </Select>
  //   </Stack>
  // ]

  return (
    <Stack spacing={2} px={2}>
      {/* <NavigationTabs panels={ONPREMISE_CONFIG_TYPE_PANELS} tabs={ONPREMISE_CONFIG_TYPE_TABS}
        value={onPremType}
        setValue={setOnPremType} /> */}
      <Typography fontSize="15px">Choose GPU Nodes</Typography>
      <Select
        size='small'
        multiple
        displayEmpty
        value={watch("initial_worker_config.on_prem_node_ids") || []}
        onChange={(e) => setValue("initial_worker_config.on_prem_node_ids", e.target.value)}
        input={<OutlinedInput />}
        renderValue={(selected, idx) => (
          <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {
              selected.length === 0 ? <Typography fontSize="15px">Choose GPU Nodes</Typography>
                :
                selected?.map((value) => (
                  <Chip key={value} sx={{
                    bgcolor: color.lightBlue,
                    borderRadius: "6px",
                    fontSize: "12px",
                  }} label={value} />
                ))
            }
          </Box>
        )}
        sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
      >
        {
          gpuOptions?.filter(gpu => gpu?.role === "INFERENCE_CONTROLLER").map((gpu, idx) => (
            <MenuItem
              key={idx}
              value={gpu.ip}
              sx={{ fontSize: "15px" }}
            >
              {gpu.ip}{gpu.gpu_count > 0 && " - " + gpu.gpu_count + "x" + gpu.gpu_type}
            </MenuItem>
          ))
        }
      </Select>
      <Stack gap={1} >
        <Typography fontSize="15px">GPU Type</Typography>
        <InputField
          state={
            watch("initial_worker_config.on_prem_node_ids")
              ?.reduce((acc, node) => acc + gpuOptions.find(gpu => gpu.ip === node)?.gpu_type, "")}
          setState={(e) => { }} width="70%" />
      </Stack>
      {
        !watch("initial_worker_config.use_cloudburst") &&
        <Stack spacing={1}>
          <Typography fontSize="15px">Number of GPUs Per Worker</Typography>
          <InputField width="70%"
            // state={} setState={(e) => setGPUPerNode(e.target.value)}
            register={register} field="initial_worker_config.initial_workers_gpu_num"
            watch={watch}
            placeholder="Enter number of GPUs" />
        </Stack>
      }
      <FormControlLabel sx={{ p: 1 }}
        value={watch("initial_worker_config.use_cloudburst")}
        control={
          <BoxSwitch
            onChange={(e) => setValue("initial_worker_config.use_cloudburst", e.target.checked)}
          />
        }
        label={<Stack direction="row" alignItems="center">
          <Typography fontSize="17px" mx={1}>Use Cloudburst</Typography>
        </Stack >}
      />
      {
        watch("initial_worker_config.use_cloudburst") &&
        <CloudConfig
          cloudProvider={cloudProvider} setCloudProvider={setCloudProvider} regionOptions={regionOptions}
          region={region} setRegion={setRegion} instanceOptions={instanceOptions}
          gpuOptions={gpuTypeOptions} cloudProviderOptions={cloudProviderOptions}
          cloudGpuWorkerConfig={cloudGpuWorkerConfig} setCloudGPUWorkerConfig={setCloudGPUWorkerConfig}
          register={register} setValue={setValue} watch={watch}
        />
      }
    </Stack >
  )
}
