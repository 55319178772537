// import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Chip, Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
// import { Link } from 'react-router-dom'
import DeleteConfirmationModal from '../Components/DeleteConfirmation/DeleteConfirmationModal'
import CustomButton from '../Components/UiComponents/CustomButton'
import Loader from '../Components/UiComponents/Loader'
import { getStatus } from "../Configs/GPUMachinesConstants"
import { useDeleteGPUMachineMutation, useGetGPUMachineQuery } from '../Services/gpuMachinesApi'
// import { useGetSecretQuery } from '../Services/secretsApi'
import InputField from '../Components/UiComponents/InputField'
import { color } from '../Styles/Color'
import { NotFoundErrorPage } from './NotFoundErrorPage'

export const GPUMachineDetails = () => {

  const location = useLocation()

  const { data, isLoading } = useGetGPUMachineQuery(location.pathname.split('/').pop(), {
    skip: false,
  })

  // const { data: secret, isLoading: isLoadingSecret } =
  //   useGetSecretQuery(`INTERNAL_SSH_PRIVATE_KEY_${data?.ssh_key_id}`, {
  //     skip: !data || !data?.ssh_key_id
  //   })

  const [deleteGPU, { isLoading: isDeleting }] = useDeleteGPUMachineMutation()

  // const [isCopied, setIsCopied] = useState(false)

  // async function copyTextToClipboard(text) {
  //   if ('clipboard' in navigator) {
  //     return await navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand('copy', true, text);
  //   }
  // }

  // const handleCopyClick = (text) => {
  //   copyTextToClipboard(text)
  //     .then(() => {
  //       setIsCopied(true);
  //       setTimeout(() => {
  //         setIsCopied(false);
  //       }, 1500);
  //     })
  // }


  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  useEffect(() => {
    if (isConfirmedDelete) {
      deleteGPU(data?.id)
    }
  }, [data?.id, deleteGPU, isConfirmedDelete])

  const handleDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  if (isLoading || isDeleting
    // || isLoadingSecret
  ) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return data ? (
    <Stack
      spacing={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
      py={1}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Typography fontSize="16px">GPU ID</Typography>
        <Typography color={color.primary} fontSize="16px">{data.id}</Typography>
      </Stack>
      <Stack p={1} pb={2} spacing={2} width="75%"
        bgcolor={color.secondaryBackground} borderRadius="8px"
        border={`1px solid ${color.borders}`}
      >
        <Stack width="30%">
          {getStatus(data?.status)}
        </Stack>
        <Stack px={3} spacing={2}>
          {/* <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">SSH Command</Typography>
            <Typography
              fontSize="15px"
              width="60%"
              bgcolor={color.white}
              border={`1px solid ${color.borders}`}
              borderRadius="8px"
              p={1}
            >
              {data.ssh_command || "dummy command"}
            </Typography>
            <Tooltip title={isCopied ? "Copied" : "Copy"}>
              <ContentCopyIcon
                sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                onClick={() => handleCopyClick(data.id)}
              />
            </Tooltip>
          </Stack> */}
          {/* <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">SSH Private Key</Typography>
            {
              secret ?
                <CustomButton width="20%">
                  <a download={`${data.id}_rsa.txt`}
                    target="_blank"
                    rel="noreferrer"
                    href={URL.createObjectURL(new Blob([JSON.stringify(secret)], { type: 'text/plain' }))}
                    style={{
                      textDecoration: "inherit",
                      color: "inherit",
                    }}
                  >
                    Download
                  </a>
                </CustomButton>
                :
                <Typography fontSize="15px">( not available )</Typography>
            }
          </Stack> */}
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">GPU Type</Typography>
            <Typography color={color.primary} fontSize="15px">
              {data.instance_details.gpu_type}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Number of GPUs</Typography>
            <Typography color={color.primary} fontSize="15px">
              {data.instance_details.gpu_count}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Instance Type</Typography>
            <Typography color={color.primary} fontSize="15px">
              {data.instance_details.instance_type}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Memory</Typography>
            <Typography color={color.primary} fontSize="15px">
              {data.instance_details.memory}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Price / Hr</Typography>
            <Typography color={color.primary} fontSize="15px">
              {Math.round(data.instance_details.on_demand * 1000) / 1000}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Result Store</Typography>
            <Typography color={color.primary} fontSize="15px">
              {data.artifacts_store_name || "None"}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Region</Typography>
            <Chip
              sx={{
                bgcolor: color.lightBlue,
                color: color.primary,
                borderRadius: "20px",
                minWidth: "15%"
              }}
              label={
                <Stack direction="row" alignItems="center" gap={1} fontSize="12px">
                  {data.instance_details.region}
                </Stack>
              }
            />
          </Stack>
          {/* {
            // data.link &&
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">Jupiter Notebook</Typography>
              <Link to={data.link + "/metrics"} target="_blank">
                <Typography color={color.primary} fontSize="15px">
                  {data?.link || "www.jupiter-notebook.dummy"}
                </Typography>
              </Link>
            </Stack >
          }
          <Alert severity="info" square={false} sx={{ width: "80%", fontSize: "14px" }}>
            For Jupiter Notebook use GPU Machine ID as token.
          </Alert> */}
        </Stack >
        <Stack direction="row" justifyContent="end" gap={1} width="95%">
          {
            !(data.status === "DELETED" || data.status === "DELETING") &&
            <CustomButton width="20%" onClick={() => setIsDeleteModalOpen(true)} color="#FF6B6A">
              Delete
            </CustomButton>
          }
        </Stack>
      </Stack >
      {
        data.docker_config &&
        <Stack p={1} pb={2} spacing={2} width="75%"
          bgcolor={color.secondaryBackground} borderRadius="8px"
          border={`1px solid ${color.borders}`}
        >
          <Chip
            sx={
              {
                bgcolor: "#FFFFFF",
                color: color.primary,
                width: "30%",
                borderRadius: "6px",
                fontSize: "15px"
              }
            }
            label={
              <Stack gap={1} direction="row" alignItems="center">
                Docker Configuration
              </Stack>
            }
          />
          <Stack px={3} spacing={2}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">Docker Image</Typography>
              <Typography color={color.primary} fontSize="15px">
                {data.docker_config?.image}
              </Typography>
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">Docker Args</Typography>
              <Typography color={color.primary} fontSize="15px">
                {data.docker_config?.args}
              </Typography>
            </Stack>
            {
              data.dockerConfig?.env_vars &&
              <Stack spacing={2}>
                <Typography fontSize="15px">Environment Variables</Typography>
                <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
                  <Stack width="48%" gap={1}>
                    <Typography fontSize="15px">
                      Key
                    </Typography>

                  </Stack>
                  <Stack width="48%" gap={1}>
                    <Typography fontSize="15px">
                      Value
                    </Typography>
                  </Stack>
                </Stack>
                {
                  Object.entries(data.dockerConfig?.env_vars || {}).map((entry, idx) => (
                    <Stack direction="row" alignItems="center" fontSize="15px" gap={2} key={idx}>
                      <InputField
                        width="48%"
                        disabled={true}
                        state={entry[0]}
                      />
                      <InputField
                        width="48%"
                        disabled={true}
                        state={entry[1]}
                      />
                    </Stack>
                  ))
                }
              </Stack>
            }
            {
              data.dockerConfig?.ports?.length > 0 &&
              <Stack spacing={2}>
                <Typography fontWeight="600" fontSize="15px">
                  Ports
                </Typography>
                <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
                  <Stack width="48%" gap={1}>
                    <Typography fontSize="15px">
                      Container Port
                    </Typography>

                  </Stack>
                  <Stack width="48%" gap={1}>
                    <Typography fontSize="15px">
                      Host Port
                    </Typography>
                  </Stack>
                </Stack>
                {
                  data.dockerConfig?.ports?.map((port, idx) => (
                    <Stack direction="row" alignItems="center" fontSize="15px" gap={2} key={idx}>
                      <InputField
                        width="48%"
                        disabled={true}
                        state={port.container_port}
                      />
                      <InputField
                        width="48%"
                        disabled={true}
                        state={port.host_port}
                      />
                    </Stack>
                  ))
                }
              </Stack>}
          </Stack >
        </Stack>
      }
      <DeleteConfirmationModal
        handleDelete={handleDelete} itemName="GPU Machine"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />

    </Stack>
  ) : (
    <NotFoundErrorPage />
  )
}
