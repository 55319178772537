import CloudSyncIcon from '@mui/icons-material/CloudSync';
import { Chip, FormControlLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { CONFIGURE_OPTIONS } from '../../Configs/JobConstants';
import { color } from '../../Styles/Color';
import BoxSwitch from '../UiComponents/BoxSwitch';
import CustomToggleButton from '../UiComponents/CustomToggleButton';
import InputField from '../UiComponents/InputField';

export const DeploymentConfig = ({
  watch, setValue, userClouds, infType, register, onPremNodes, selected, setSelected
}) => {

  const [engine, setEngine] = useState("")

  const regions = userClouds
    ?.filter(d => ["AWS", "AZURE", "GCP", "SCALEGENAI"].includes(d.cloud_provider))
    ?.find(c => c.cloud_provider === watch("controller_cloud_config.cloud_provider"))
    ?.regions

  useEffect(() => {
    setValue("controller_cloud_config.region", regions[0])
  }, [regions, setValue])

  return (
    <Stack bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Stack p={3} spacing={2}>
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "25%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <CloudSyncIcon sx={{ color: color.primary, fontSize: "16px" }} />
              Controller Configurations
            </Stack>
          } />
        <Stack spacing={2}>
          <Typography fontWeight="600" fontSize="15px">
            Inference Engine
          </Typography>
          <Select
            displayEmpty
            size='small'
            sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
            value={engine}
            onChange={(e) => setEngine(e.target.value)}
          >
            <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
              Choose Inference Engine
            </MenuItem>
            {
              (
                infType === "llm"
                  ?
                  ["ScaleGenAI Inference Engine (Throughput Optimized)", "ScaleGenAI Inferene Engine (Latency Optimized)", "vLLM"]
                  :
                  ["ScaleGenAI Embeddings Engine", "TEI (Text Embeddings Inference)"]
              ).map((option, idx) => (
                <MenuItem value={option} key={idx} sx={{ fontSize: "15px" }}>
                  {option}
                </MenuItem>
              ))
            }
          </Select>
        </Stack>
        <CustomToggleButton options={CONFIGURE_OPTIONS} selected={selected} setSelected={setSelected} />
        {
          selected === CONFIGURE_OPTIONS[0] ?
            <Stack px={2} spacing={2}>
              <Typography fontWeight="600" fontSize="15px">
                Cloud Provider
              </Typography>
              <Select
                displayEmpty
                size='small'
                sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
                value={watch("controller_cloud_config.cloud_provider")}
                onChange={(e) => {
                  setValue("controller_cloud_config.cloud_provider", e.target.value)
                  if (e.target.value === "GCP") {
                    setValue("controller_cloud_config.vpc_id", null)
                    setValue("controller_cloud_config.use_api_gateway", null)
                  } else {
                    setValue("controller_cloud_config.vpc_id", "")
                    setValue("controller_cloud_config.use_api_gateway", true)
                  }
                }}
              >
                <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
                  Choose Cloud Provider
                </MenuItem>
                {
                  userClouds
                    ?.filter(d => ["AWS", "GCP", "SCALEGENAI"].includes(d.cloud_provider))
                    // ?.filter(c => c.cloud_provider === "AWS" || c.cloud_provider === "GCP")
                    .map((cloud, idx) => (
                      <MenuItem value={cloud.cloud_provider} key={idx} sx={{ fontSize: "15px" }}>
                        {cloud.cloud_provider}
                      </MenuItem>
                    ))
                }
                {
                  userClouds
                    ?.filter(d => ["AZURE"].includes(d.cloud_provider))
                    .map((cloud, idx) => (
                      <MenuItem value={cloud.cloud_provider} disabled key={idx + 1} sx={{ fontSize: "15px" }}>
                        {cloud.cloud_provider}
                      </MenuItem>
                    ))
                }
              </Select>
              <Typography fontWeight="600" fontSize="15px">
                Region
              </Typography>
              <Select
                displayEmpty
                size='small'
                sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
                value={watch("controller_cloud_config.region")}
                onChange={(e) => setValue("controller_cloud_config.region", e.target.value)}
              >
                <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
                  Choose Region
                </MenuItem>
                {
                  regions?.map((path, idx) => (
                    <MenuItem value={path} key={idx} sx={{ fontSize: "15px" }}>
                      {path}
                    </MenuItem>
                  ))
                }
              </Select>
              {
                watch("controller_cloud_config.cloud_provider") === "AWS" &&
                <Stack spacing={2} >
                  <Typography fontSize="15px">VPC ID</Typography>
                  <InputField
                    // state={lookupTimeframe}
                    //   setState={(e) => setLookupTimeframe(e.target.value)} 
                    register={register} field="controller_cloud_config.vpc_id"
                    watch={watch}
                    width="70%"
                  />
                </Stack>
              }
              <Stack direction="row" gap={2} p={2}>
                {
                  (watch("controller_cloud_config.cloud_provider") === "AWS" ||
                    watch("controller_cloud_config.cloud_provider") === "SCALEGENAI") &&
                  <FormControlLabel
                    value={watch("controller_cloud_config.use_api_gateway")}
                    control={
                      <BoxSwitch
                        onChange={(e) => {
                          setValue("controller_cloud_config.use_api_gateway", e.target.checked)
                          if (e.target.checked) {
                            setValue("controller_cloud_config.public_url", true)
                            setValue("controller_cloud_config.use_ssl", true)
                          }
                        }
                        }
                      />
                    }
                    label={
                      <Stack direction="row" alignItems="center">
                        <Typography fontSize="17px" mx={1}>Use API Gateway</Typography>
                      </Stack >
                    }
                  />
                }
                <FormControlLabel
                  value={watch("controller_cloud_config.public_url")}
                  control={
                    <BoxSwitch
                      onChange={(e) => setValue("controller_cloud_config.public_url", e.target.checked)}
                    />
                  }
                  disabled={watch("controller_cloud_config.use_api_gateway") === true}
                  label={
                    <Stack direction="row" alignItems="center">
                      <Typography fontSize="17px" mx={1}>Public URL</Typography>
                    </Stack >
                  }
                />
                <FormControlLabel
                  value={watch("controller_cloud_config.use_ssl")}
                  control={
                    <BoxSwitch
                      onChange={(e) => setValue("controller_cloud_config.use_ssl", e.target.checked)}
                    />
                  }
                  disabled={watch("controller_cloud_config.use_api_gateway") === true}
                  label={
                    <Stack direction="row" alignItems="center">
                      <Typography fontSize="17px" mx={1}>Use SSL</Typography>
                    </Stack >
                  }
                />
              </Stack>
            </Stack> :
            <Stack p={2} spacing={2}>
              <Select
                displayEmpty
                size='small'
                sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
                value={watch("controller_on_prem_config.on_prem_node_id")}
                onChange={(e) => setValue("controller_on_prem_config.on_prem_node_id", e.target.value)}
              >
                <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
                  Choose On-prem Node
                </MenuItem>
                {
                  onPremNodes?.filter(gpu => gpu?.role === "INFERENCE_CONTROLLER").map((gpu, idx) => (
                    <MenuItem
                      key={idx}
                      value={gpu.ip}
                      sx={{ fontSize: "15px" }}
                    >
                      {gpu.ip}{gpu.gpu_count > 0 && " - " + gpu.gpu_count + "x" + gpu.gpu_type}
                    </MenuItem>
                  ))
                }
              </Select>
              <FormControlLabel
                value={watch("controller_on_prem_config.use_ssl")}
                control={
                  <BoxSwitch
                    onChange={(e) => setValue("controller_on_prem_config.use_ssl", e.target.checked)}
                  />
                }
                label={
                  <Stack direction="row" alignItems="center">
                    <Typography fontSize="17px" mx={1}>Use SSL</Typography>
                  </Stack >
                }
                sx={{ px: 2 }}
              />
            </Stack>
        }
      </Stack>
    </Stack >

  )
}
