import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import LLMGatewayList from '../Components/LLMGatewayComponents/LLMGatewayList';
import CustomButton from "../Components/UiComponents/CustomButton";
import Loader from '../Components/UiComponents/Loader';
import { PAGE_ROUTES } from '../Configs/Routes';
import { useGetLLMGatewaysQuery } from '../Services/llmgatewayApi';
import { color } from '../Styles/Color';

export const LLMGatewayView = () => {

  const [searchLLMGateway, setSearchLLMGateway] = useState("")
  const [searchedData, setSearchedData] = useState([])
  const nav = useNavigate()

  const { data, isLoading, isSuccess } =
    useGetLLMGatewaysQuery(null, { pollingInterval: 10000 });

  useEffect(() => {
    if ((isSuccess && data)) {
      const options = {
        keys: ['name'],
        includeScore: true,
      }

      const fuse = new Fuse((data), options)

      if (searchLLMGateway.length > 0) {
        setSearchedData(
          fuse.search(searchLLMGateway)
            .filter(j => j.score < 0.5)
            .map(j => j.item)
        )
      } else {
        setSearchedData(data)
      }
    }
  }, [data, isSuccess, searchLLMGateway])

  if (isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (

    <Stack spacing={3}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" pb={2}>LLM Gateway</Typography>
      <Stack gap={4} direction="row">
        <OutlinedInput
          size='small'
          fullWidth
          border={`1px solid ${color.borders}`}
          sx={{
            fontSize: '15px',
            borderRadius: "8px",
            boxShadow: "0px 0px 4px 0px #00000029"
          }}
          placeholder='Search LLM Gateway'
          value={searchLLMGateway}
          onChange={(e) => setSearchLLMGateway(e.target.value)}
          startAdornment={<InputAdornment position="start">
            <SearchIcon fontSize='small' />
          </InputAdornment>}
        />
        <CustomButton height="42px" width="50%" onClick={() => nav(PAGE_ROUTES.newLLMGateway)}>
          <Stack direction="row" alignItems="center" height="32px" gap={2}>
            <PlayArrowIcon />
            <Typography fontSize="15px">New LLM Gateway</Typography>
          </Stack>
        </CustomButton>
      </Stack>
      <LLMGatewayList data={searchedData} />
    </Stack>
  )
}
