import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { FormControlLabel, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import { color } from '../../../Styles/Color';
import BoxSwitch from '../../UiComponents/BoxSwitch';
import InputField from '../../UiComponents/InputField';

export const AdvancedVM = ({ newVm, setNewVm }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Stack
      p={1}
      border={`1px solid ${color.borders}`}
      borderRadius="8px"
      bgcolor={!isOpen && color.primary}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={() => setIsOpen(!isOpen)}
        sx={{
          '&:hover': {
            cursor: 'pointer'
          }
        }}
      >
        <Stack
          fontWeight="600"
          color={isOpen ? color.primary : "#FFFFFF"}
          fontSize="15px"
          direction="row"
          alignItems="center"
          gap={1}
        >
          <Stack height="20px" width="5px" borderRadius="8px" bgcolor={color.primary}></Stack>
          Advanced
        </Stack>
        {
          !isOpen ? <KeyboardArrowDownIcon sx={{ color: color.white }} />
            : <KeyboardArrowUpIcon sx={{ color: color.primary }} />
        }
      </Stack>
      {
        isOpen &&
        <Stack gap={1.5} p={2}>
          <Typography fontWeight="600" fontSize="15px">
            Filter
          </Typography>
          <InputField
            placeholder="Enter filter value"
            state={newVm.filter}
            setState={(e) => setNewVm((prev) => ({ ...prev, filter: e.target.value }))}
          />
          <FormControlLabel
            sx={{
              p: 2
            }}
            control={
              <BoxSwitch
                onChange={(e) => setNewVm(prev => ({ ...prev, unravelArchives: e.target.checked }))}
              />
            }
            label={
              <Typography fontSize="17px">VirtualMount unravelArchives</Typography>
            }
          />
          <FormControlLabel
            sx={{
              p: 2
            }}
            control={
              <BoxSwitch
                onChange={(e) => setNewVm(prev => ({ ...prev, prefetch: e.target.checked }))}
              />
            }
            label={
              <Typography fontSize="17px">VirtualMount prefetch</Typography>
            }
          />
        </Stack>
      }
    </Stack >
  )
}
