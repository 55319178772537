import ClearIcon from '@mui/icons-material/Clear';
import DnsIcon from '@mui/icons-material/Dns';
import { Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLaunchGPUMachineMutation } from '../../Services/gpuMachinesApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';
import { GPUMachineOptions } from './GPUMachineOptions';
import { LaunchGPU } from './LaunchGPU';


export const AddGPUMachineModal = ({ isOpen, setIsOpen, gpuList }) => {

  const [machine, setMachine] = useState(null)
  const [selected, setSelected] = useState(0)
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false)
  // const [gpuRegion, setGPURegion] = useState("")
  // const [gpuDataset, setGPUDataset] = useState("")
  const [gpuStore, setGPUStore] = useState("")
  const [dockerConfig, setDockerConfig] = useState({
    "args": "",
    "env_vars": {},
    "image": "",
    "ports": []
  })

  const [filterGPUType, setFilterGPUType] = useState("")
  const [filterGPUCount, setFilterGPUCount] = useState("")
  const [filteredGPUList, setFilteredGPUList] = useState(gpuList)

  useEffect(() => {
    setFilteredGPUList(
      gpuList
        .filter(gpu => filterGPUType.length > 0 ? gpu.gpu_type.includes(filterGPUType) : gpu)
        .filter(gpu => filterGPUCount > 0 ? gpu.gpu_count === filterGPUCount : gpu)
    )
  }, [filterGPUCount, filterGPUType, gpuList])

  const [launch, { isLoading, isSuccess }] = useLaunchGPUMachineMutation()

  useEffect(() => {
    if (isSuccess) {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess])

  const handleClose = () => {
    setMachine(null)
    setSelected(0)
    // setGPURegion("")
    setFilterGPUCount("")
    setFilterGPUType("")
    // setGPUDataset("")
    setGPUStore("")
    setDockerConfig(
      {
        "args": "",
        "env_vars": {},
        "image": "",
        "ports": []
      }
    )
    setIsOpen(false)
  }

  const handleNext = () => {
    setMachine(filteredGPUList[selected])
    // setGPURegion(filteredGPUList[selected].availability[0].sg_region)
  }

  const handleLaunch = () => {
    const launchConfig = {
      "machine_avail_id": machine.id,
      "artifacts_store_name": gpuStore.length > 0 ? gpuStore : null,
    }

    if (isAdvancedOpen && dockerConfig.image.length > 0) {
      launchConfig.docker_config = dockerConfig
    }

    launch(launchConfig)
  }

  return (
    <Dialog maxWidth="xl" open={isOpen} onClose={handleClose} PaperProps={{ sx: { borderRadius: "12px", p: 1 } }}>
      <DialogTitle sx={{ p: 2, pb: 1 }}>
        <Stack justifyContent="space-between" direction="row">
          <Stack direction="row" fontSize="1.25rem" alignItems="center">
            <Stack
              border="2px solid #92CBFF"
              boxShadow="0px 2px 4px 0px #0000001F"
              padding={1}
              borderRadius="10px"
            >
              <DnsIcon fontSize='20px' sx={{ color: color.primary }} />
            </Stack>
            <Typography fontFamily="IBMPlexSansSemiBold" sx={{ mx: 2 }}>Add GPU Machine</Typography>
          </Stack>
          <IconButton onClick={handleClose}>
            <ClearIcon sx={{ fontSize: '24px' }} />
          </IconButton>
        </Stack>
      </DialogTitle>
      <Divider variant="middle" sx={{ bgcolor: '#92CBFF' }} />
      <DialogContent sx={{
        minHeight: "60vh",
        width: "60vw",
        p: 2,
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}>
        {
          isLoading === true ?
            <Stack height="50vh">
              <Loader />
            </Stack>
            :
            !machine ?
              <GPUMachineOptions
                filterGPUCount={filterGPUCount} setFilterGPUCount={setFilterGPUCount}
                selected={selected} setSelected={setSelected} filteredList={filteredGPUList}
                filterGPUType={filterGPUType} setFilterGPUType={setFilterGPUType}

              />
              : <LaunchGPU selected={machine}
                // gpuRegion={gpuRegion} setGPURegion={setGPURegion}
                setGPUStore={setGPUStore} gpuStore={gpuStore}
                dockerConfig={dockerConfig} setDockerConfig={setDockerConfig}
                isOpen={isAdvancedOpen} setIsOpen={setIsAdvancedOpen}
              // setGPUDataset={setGPUDataset} gpuDataset={gpuDataset}
              />
        }
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <CustomButton
          onClick={!machine ? handleNext : handleLaunch}
          width={!machine ? "20%" : "25%"}
        >
          {!machine ? "Next" : "Launch"}
        </CustomButton>
      </DialogActions>
    </Dialog>
  )
}
