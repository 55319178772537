import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import RestoreIcon from '@mui/icons-material/Restore';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { Chip, Stack, TableFooter, TablePagination, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { color } from '../../Styles/Color';


function createData(id, name, startTime, endTime, cost, status, type) {
  cost = Math.round(cost * 100) / 100
  startTime = startTime?.split(".")[0]
  endTime = endTime?.split(".")[0]
  type = type.toLowerCase()
  return { id, name, startTime, endTime, cost, status, type };
}

export const getStatus = (status) => {
  switch (status) {
    case "stopped": {
      return <Chip
        sx={{
          bgcolor: '#D8ECFF',
          color: color.primary,
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <DoneAllIcon sx={{ fontSize: "15px" }} /> Stopped
          </Stack>
        }
      />
    }

    case "failed": {
      return <Chip
        sx={{
          bgcolor: '#FFD2D2',
          color: "#FF6B6A",
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <CancelOutlinedIcon sx={{ fontSize: "15px" }} /> Failed
          </Stack>
        }
      />
    }

    case "stopping": {
      return <Chip
        sx={{
          bgcolor: '#FFEEC3',
          color: "#FF8A00",
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <RadioButtonCheckedIcon sx={{ fontSize: "15px" }} /> Stopping
          </Stack>
        }
      />
    }

    case "running": {
      return <Chip
        sx={{
          bgcolor: '#BEFFD0',
          color: "#2C974A",
          borderRadius: "6px",
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <SettingsBackupRestoreIcon sx={{ fontSize: "15px" }} /> Running
          </Stack>
        }
      />
    }

    case "queued": {
      return <Chip
        sx={{
          bgcolor: '#FAFFC3',
          color: "#DAC400",
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <RestoreIcon sx={{ fontSize: "15px" }} /> Queued
          </Stack>
        }
      />
    }

    case "deleted": {
      return <Chip
        sx={{
          bgcolor: '#E4E4E4',
          color: "#ABABAB",
          borderRadius: "6px"
        }}
        label={
          <Stack direction="row" alignItems="center" gap={1} fontSize="15px">
            <AutoDeleteIcon sx={{ fontSize: "15px" }} /> Deleted
          </Stack>
        }
      />
    }
    default: return <></>
  }
}

const Row = ({ row }) => {
  const navigate = useNavigate()
  return (
    <TableRow
      hover
      sx={{
        '&:last-child td, &:last-child th': {
          border: 0
        },
        '&:hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => navigate("/" + row.type + "/" + row.id)}
    >
      <TableCell component="th" scope="row"
        sx={{
          fontSize: "13px",
        }}
      >
        {
          row.id.length > 25 ?
            row.id.slice(0, 25) + "..." : row.id
        }
      </TableCell>
      <TableCell component="th" scope="row"
        sx={{
          fontSize: "13px",
        }}
      >
        {
          row.name.length > 25 ?
            row.name.slice(0, 25) + "..." : row.name
        }
      </TableCell>
      <TableCell
        sx={{
          fontSize: "13px",
        }}
      >
        {row.startTime}
      </TableCell>
      <TableCell
        sx={{
          fontSize: "13px",
        }}
      >
        {row.endTime}
      </TableCell>
      <TableCell>
        <Typography color={color.primary} fontSize="13px">
          $ {row.cost}
        </Typography>
      </TableCell>
      <TableCell
        sx={{
          fontSize: "13px",
        }}
      >
        {
          getStatus(row.status.toLowerCase())
        }
      </TableCell>
    </TableRow >
  )
}

export default function JobsList({ data, height }) {

  const rows = (data || []).map((d) =>
    createData(d.id, d.name, d.start_time, d.end_time, d.cost, d.status, d.spec.type))

  const [page, setPage] = useState(0)

  const rowsPerPage = 10

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (data && (data.length < page * rowsPerPage)) {
      setPage(0)
    }
  }, [data, page])

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000029",
        maxHeight: height || "65vh",
      }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Job Id</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="20%">Job Name</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="17%">Start Time</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="17%">End Time</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="10%">Cost</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              (rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) || []).map((row, idx) => (
                <Row row={row} key={idx} />
              ))
              :
              <Stack height="10vh" justifyContent="center" px={2}>
                <Typography color={color.primary} fontSize="15px">No data available</Typography>
              </Stack>
          }
        </TableBody>
        <TableFooter
          sx={{
            left: 0,
            bottom: 0,
            zindex: 2,
            position: 'sticky'
          }}
        >
          <TableRow sx={{ backgroundColor: color.secondaryBackground }}>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={7}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}