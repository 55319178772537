import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { Box, Chip, FormControlLabel, MenuItem, OutlinedInput, Select, Stack, Typography } from '@mui/material';
import { color } from '../../Styles/Color';
import BoxSwitch from '../UiComponents/BoxSwitch';
import InputField from '../UiComponents/InputField';

export const LLMGatewayConfig = ({ watch, setValue, register, llmProviders }) => {

  return (
    <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
      <Chip
        sx={
          {
            bgcolor: "#FFFFFF",
            color: color.primary,
            width: "25%",
            borderRadius: "6px",
            fontSize: "15px"
          }
        }
        label={
          <Stack gap={1} direction="row" alignItems="center">
            <AppRegistrationIcon sx={{ color: color.primary, fontSize: "16px" }} />
            LLM Gateway Configurations
          </Stack>
        } />
      <Stack gap={4} direction="row" p={2}>
        <FormControlLabel
          value={watch("has_semantic_caching")}
          control={<BoxSwitch onChange={(e) => setValue("has_semantic_caching", e.target.checked)} />}
          label={
            <Stack direction="row" alignItems="center">
              <Typography fontSize="17px">Enable Semantic Caching</Typography>
            </Stack>
          }
        />
        <FormControlLabel
          value={watch("has_pii_anon")}
          control={<BoxSwitch onChange={(e) => setValue("has_pii_anon", e.target.checked)} />}
          label={
            <Stack direction="row" alignItems="center">
              <Typography fontSize="17px">Enable PII Anonymization</Typography>
            </Stack >
          }
        />
        <FormControlLabel
          value={watch("has_prompt_inject_check")}
          control={<BoxSwitch onChange={(e) => setValue("has_prompt_inject_check", e.target.checked)} />}
          label={
            <Stack direction="row" alignItems="center">
              <Typography fontSize="17px">Enable Prompt injection checks</Typography>
            </Stack>
          }
        />
      </Stack>
      <Stack gap={1} >
        <Typography fontSize="15px">Number of Retries</Typography>
        <InputField placeholder="Enter value"
          // state={faceToken} setState={(e) => setFaceToken(e.target.value)} 
          register={register}
          field="num_reties"
          width="70%"
          watch={watch}
        />
      </Stack>
      <Stack spacing={1}>
        <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold" >
          LLM Providers
        </Typography>
        <Select
          size='small'
          multiple
          displayEmpty
          value={watch("llm_apis")}
          onChange={(e) => setValue("llm_apis", e.target.value)}
          input={<OutlinedInput placeholder="Choose the providers" />}
          renderValue={(selected, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography fontSize="15px">Choose the providers</Typography>
                  :
                  selected.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))
              }
            </Box>
          )}
          sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
        >
          {llmProviders.map((option, idx) => (
            <MenuItem
              key={idx}
              value={option.provider}
              sx={{ fontSize: "15px" }}
            >
              {option.provider}
            </MenuItem>
          ))}
        </Select>
      </Stack>
    </Stack>
  )
}
