import { Stack, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useGetLatestLinesMutation } from '../../Services/livelogsApi';
import { color } from '../../Styles/Color';
import CustomButton from '../UiComponents/CustomButton';
import Loader from '../UiComponents/Loader';

export const LatestLogViewer = ({ job_id, trial_id }) => {
  const scrollRef = useRef(null);
  const [mtime, setMtime] = useState('0');
  const [getLatestLines] = useGetLatestLinesMutation()
  const [latestLogs, setLatestLogs] = useState(null)
  const [fetchLiveLogs, setFetchLiveLogs] = useState(true)
  const [pauseRealTime, setPauseRealTime] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    if (latestLogs)
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [latestLogs]);

  useEffect(() => {
    if (!pauseRealTime && fetchLiveLogs) {
      let started = 0
      let done = 0
      const intervalCall = setInterval(() => {
        let current = started++
        getLatestLines({ job_id, trial_id, mtime })
          .unwrap()
          .then((res) => {
            // accept write if only consistent
            if (done <= current) {
              setLatestLogs(res.content)
              setMtime(res.mtime)
              done = current
            }
            setLoading(false)
          })
          .catch((err) => {
            setFetchLiveLogs(false)
            setLatestLogs("Live logs are not available at the moment. Kindly download the logs to check the progress.")
            setLoading(false)
          })
      }, 1000);
      return () => {
        // clean up
        clearInterval(intervalCall);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pauseRealTime]);

  const handleClick = () => {
    setPauseRealTime(!pauseRealTime)
    setMtime('0')
  }

  if (loading) {
    return <Stack height="50vh"><Loader /></Stack>
  }

  if (!latestLogs) {
    return <Typography color={color.primary} fontSize="15px">
      Can not Retrieve Logs , Please check again later.
    </Typography>
  }

  return (
    <Stack spacing={2}>
      <CustomButton
        width="30%"
        background={!pauseRealTime && "#FF6B6A"}
        onClick={handleClick}
      >
        <Typography fontSize="15px">{pauseRealTime ? 'Start Live Logging' : 'Stop Live Logging'}</Typography>
      </CustomButton>
      <Stack
        px={2}
        bgcolor={color.secondaryBackground}
        borderRadius="8px"
        height="60vh"
        overflow="auto"
        border={`1px solid ${color.borders}`}
        fontFamily="IBMPlexSansSemiBold"
        fontSize="14px"
        lineHeight="24px"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          }
        }}
      >
        <p dangerouslySetInnerHTML={{ __html: latestLogs.replace(/(?:\r\n|\r|\n)/g, "<br />") }} />
        <p ref={scrollRef}></p>
      </Stack>
    </Stack >
  )
}
