import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import AddNodeModal from '../Components/OnPremNodesComponents/AddNodeModal';
import NodesList from '../Components/OnPremNodesComponents/NodesList';
import CustomButton from "../Components/UiComponents/CustomButton";
import Loader from "../Components/UiComponents/Loader";
import { ON_PREM_NODE_TYPE_MAP } from "../Configs/OnPremNodesContants";
import { useDeleteUserOnPremNodeMutation, useGetUserOnPremNodesQuery } from '../Services/nodeApi';

export const OnPremNodesView = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { data: fetchedData, isLoading } = useGetUserOnPremNodesQuery()

  const [deleteUserOnPremNode, { isLoading: isDeleting }] = useDeleteUserOnPremNodeMutation()

  if (isLoading || isDeleting) {
    return <Stack height="80vh" >
      <Loader />
    </Stack >
  }

  return (
    <Stack>
      <Stack spacing={3}
        overflow="auto"
        sx={{
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
        <Typography fontFamily="IBMPlexSansBold" fontSize="25px" pb={2}>On-Prem Nodes</Typography>
        <Stack alignItems="end">
          <CustomButton width="30%" onClick={() => setIsModalOpen(true)}>
            <AddIcon fontSize='small' sx={{ mr: 1 }} />
            Add On-Prem Node
          </CustomButton>
        </Stack>
        <Stack minHeight="30vh" spacing={2}>
          <Typography fontSize="16px">Controller Nodes</Typography>
          <NodesList
            data={fetchedData?.filter((item) => item.role === ON_PREM_NODE_TYPE_MAP.CONTROLLER) || []}
            handleDelete={deleteUserOnPremNode} type={ON_PREM_NODE_TYPE_MAP.CONTROLLER}
          />
        </Stack>
        <Stack minHeight="30vh" spacing={2}>
          <Typography fontSize="16px" >GPU Worker Nodes</Typography>
          <NodesList
            data={fetchedData?.filter((item) => item.role === ON_PREM_NODE_TYPE_MAP.WORKER) || []}
            handleDelete={deleteUserOnPremNode} type={ON_PREM_NODE_TYPE_MAP.WORKER}
          />
        </Stack>
      </Stack>
      <AddNodeModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </Stack >
  )
}
