import { Stack } from "@mui/material";
import { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import rehypeRaw from 'rehype-raw';
import { getInferenceCertificate } from "../../Configs/Constants";
import { setErrorMessage, setIsError } from "../../DataStore/errorSlice";
import { useGetArtifactStoragePathsQuery } from "../../Services/artifactStorageApi";
import { getArtifact } from "../../Services/artifactsApi";
import CustomButton from "../UiComponents/CustomButton";
import Loader from "../UiComponents/Loader";

const CodeRenderer = (props) => {
  return <SyntaxHighlighter language={props.language} >
    {props.children}
  </SyntaxHighlighter>;
};


export const Certificate = ({ id, logs_store, type, controller_ip }) => {

  const [loading, setLoading] = useState(false);
  const [artifactsDestinationPath, setArtifactsDestinationPath] = useState("");
  const platformBearer = useSelector((state) => state.userState.platformBearer)
  const dispatch = useDispatch()

  const {
    data: artifactStoragePaths
  } = useGetArtifactStoragePathsQuery()

  useEffect(() => {
    artifactStoragePaths?.forEach((item) => {
      if (logs_store === item.name) {
        setArtifactsDestinationPath(item.path);
      }
    })
  }, [artifactStoragePaths, logs_store])

  const Button = ({ children }) => (
    <CustomButton
      onClick={() => {
        setLoading(true)
        getArtifact(artifactsDestinationPath + "/" + id + "/root.crt", platformBearer)
          .then((response) => {
            if (response) {
              const blob = response.blob;
              const reader = new FileReader();
              reader.readAsText(blob);
              const link = document.createElement('a');
              link.href = response.href;
              link.setAttribute(
                'download',
                `${id}.cert`,
              );
              document.body.appendChild(link);

              link.click();
              // Clean up and remove the link
              link.parentNode?.removeChild(link);
              // Append to html link element page
            }
            setLoading(false)
          }).catch(error => {
            dispatch(setIsError(true))
            dispatch(setErrorMessage("Something went wrong"))
            setLoading(false)
          })
      }
      }
      width="30%"
    >
      {children}
    </CustomButton >
  );

  return (
    <Stack
      spacing={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
      height="77vh"
      pb={3}
      px={2}
      fontFamily="IBMPlexSansSemiBold"
    >
      {
        loading ?
          <Stack height="70%">
            <Loader />
          </Stack> : <ReactMarkdown
            components={{
              code: ({ node, ...props }) => <CodeRenderer {...props} />,
              button: Button
            }}
            rehypePlugins={[rehypeRaw]}
          >
            {getInferenceCertificate(id, type, controller_ip)}
          </ReactMarkdown>
      }
    </Stack>
  )
}
