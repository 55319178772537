import VisibilityIcon from '@mui/icons-material/Visibility';
import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import { color } from '../../Styles/Color';
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal';
import DeleteButton from '../UiComponents/DeleteButton';
import TeamMember from './TeamMember';

function createData(email) {
  return { email };
}

export default function TeamList({ data, handleDelete }) {

  const rows = (data || []).map((email) => createData(email))

  const [viewMember, setViewMember] = useState("")
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      handleDelete(deletingId)
      setDeletingId(null)
    }
  }, [deletingId, handleDelete, isConfirmedDelete])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  return (
    <>
      <TableContainer component={Paper}
        sx={{
          boxShadow: "0px 0px 4px 0px #00000029",
          maxHeight: "75vh"
        }}>
        <Table sx={{ minWidth: 650 }} stickyHeader>
          <TableHead>
            <TableRow >
              <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="50%">
                Email ({data ? data.length : 0})
              </TableCell>
              <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="30%">Permissions</TableCell>
              <TableCell sx={{ backgroundColor: color.secondaryBackground }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rows.length > 0 ?
                rows.map((row, idx) => (
                  <TableRow
                    key={idx}
                    hover
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell sx={{ fontSize: "13px" }}>{row.email}</TableCell>
                    <TableCell align='center'>
                      <Stack direction="row"
                        alignItems="center"
                        gap={1}
                        sx={{
                          color: color.primary,
                          fontSize: "13px",
                          "&:hover": {
                            cursor: "pointer"
                          }
                        }}
                        onClick={() => {
                          setModalIsOpen(true)
                          setViewMember(row.email)
                        }
                        }
                      >
                        <VisibilityIcon fontSize='small' />
                        View
                      </Stack>
                    </TableCell>
                    <TableCell align='center'>
                      <DeleteButton
                        onClick={() => {
                          setIsDeleteModalOpen(true)
                          setDeletingId(row.email)
                        }}
                      />
                    </TableCell>
                  </TableRow>
                )) :
                <Stack height="10vh" justifyContent="center" px={2}>
                  <Typography color={color.primary} fontSize="15px">No data available</Typography>
                </Stack>
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TeamMember member={viewMember} team={data} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}
        setMemberData={setViewMember} />
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName={"Member with email " + deletingId}
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </>
  );
}