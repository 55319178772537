import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import { Button, InputAdornment, OutlinedInput, Stack, Typography } from '@mui/material'
import Fuse from 'fuse.js'
import { useEffect, useState } from 'react'
import { useAddSecretMutation, useDeleteSecretMutation } from '../../Services/secretsApi'
import { color } from '../../Styles/Color'
import DeleteConfirmationModal from '../DeleteConfirmation/DeleteConfirmationModal'
import CustomButton from '../UiComponents/CustomButton'
import InputField from '../UiComponents/InputField'
import Loader from '../UiComponents/Loader'

export const SecretsList = ({ data }) => {

  const [key, setKey] = useState("")
  const [value, setValue] = useState("")
  const [searchedKey, setSearchedKey] = useState("")

  const [searchedData, setSearchedData] = useState(data)

  const [deleteKey, { isLoading: isDeleting }] = useDeleteSecretMutation()
  const [addKey, { isLoading: isAdding, isSuccess }] = useAddSecretMutation()

  useEffect(() => {
    if (isSuccess) {
      setKey("")
      setValue("")
    }
  }, [isSuccess])

  useEffect(() => {
    const options = {
      includeScore: true,
    }

    const fuse = new Fuse(data, options)

    if (searchedKey.length > 0) {
      setSearchedData(fuse.search(searchedKey).filter(key => key.score < 0.2).map(key => key.item))
    } else {
      setSearchedData(data)
    }
  }, [data, searchedKey])

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [deletingId, setDeletingId] = useState(null)

  useEffect(() => {
    if (isConfirmedDelete && deletingId) {
      deleteKey(key)
      setDeletingId(null)
    }
  }, [deleteKey, deletingId, isConfirmedDelete, key])

  const handleModalDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  if (isDeleting || isAdding) {
    return <Stack height="60vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
          <Stack width="40%" gap={1}>
            <Typography fontSize="15px">
              Key
            </Typography>

          </Stack>
          <Stack width="40%" gap={1}>
            <Typography fontSize="15px">
              Value
            </Typography>

          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
          <InputField
            width="40%"
            placeholder="Enter key"
            state={key}
            setState={(e) => setKey(e.target.value)}
          />
          <InputField
            width="40%"
            placeholder="Enter value"
            state={value}
            setState={(e) => setValue(e.target.value)}
          />
          <CustomButton width="10%"
            disabled={(!key || !value) && true}
            onClick={() =>
              addKey(
                {
                  secret_key: key,
                  secret_value: value,
                  typed: false
                }
              )
            }
          >
            <Stack alignItems="center" direction="row" gap={0.5} >
              <AddIcon sx={{ fontSize: "16px" }} />
              Add
            </Stack>
          </CustomButton>
        </Stack>
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Secret Keys</Typography>
        <OutlinedInput
          value={searchedKey}
          size='small'
          border={`1px solid ${color.borders}`}
          onChange={e => setSearchedKey(e.target.value)}
          sx={{
            fontSize: '15px',
            borderRadius: "8px",
            boxShadow: "0px 0px 4px 0px #00000029",
            width: "40%"
          }}

          placeholder='Search Id'
          startAdornment={<InputAdornment position="start">
            <SearchIcon fontSize='small' />
          </InputAdornment>}
        />
      </Stack>
      <Stack overflow="auto" height="60vh" spacing={2} pb={2}>
        {
          searchedData.map((key, idx) => (
            <Stack direction="row" alignItems="center" fontSize="15px" gap={2} key={idx}>
              <Stack
                px={2}
                py={1}
                color={color.primary}
                bgcolor={color.secondaryBackground}
                borderRadius="8px"
                width="40%"
              >
                {
                  key.startsWith("ENV_") ?
                    key.slice(4, key.length)
                    :
                    key
                }
              </Stack>
              <Button
                onClick={() => {
                  setIsDeleteModalOpen(true)
                  setDeletingId(key)
                }}
                sx={{
                  bgcolor: "#FFDBDB",
                  p: 1,
                  minWidth: '30px',
                  '&:hover': {
                    bgcolor: "#FFDBDB",
                  }
                }} >
                <DeleteIcon sx={{ color: "#FF6B6A", fontSize: "16px" }} />
              </Button >
            </Stack>
          ))
        }
      </Stack>
      <DeleteConfirmationModal
        handleDelete={handleModalDelete} itemName="Secret"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </Stack >
  )
}
