import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { color } from '../Styles/Color';
import { PAGE_ROUTES } from "./Routes";

export const UPLOAD_OPTIONS = Object.freeze([
  "Code from Repository",
  "Upload Code as ZIP File",
])

export const ADDITIONAL_REQUIREMENT_OPTIONS = Object.freeze([
  "Requirements File",
  "Docker Image",
])

export const REPO_HOSTS = Object.freeze([
  "Github Private",
  "Github",
  "Gitlab Private",
  "Gitlab",
  "Bitbucket Private",
  "Bitbucket",
  "S3",
  "Azure",
  "GS",
  "GDrive"
])

export const LAUNCH_JOB_OPTIONS = Object.freeze([
  {
    name: "Fine-Tune (llama and mistral)",
    rediect: PAGE_ROUTES.finetuningLlamaMistral,
    icon: <AutoFixHighIcon sx={{ color: color.primary, fontSize: "16px" }} />
  },
  {
    name: "Train From Scratch",
    rediect: PAGE_ROUTES.createJob,
    icon: <PlaylistPlayIcon sx={{ color: color.primary, fontSize: "16px" }} />
  },
  {
    name: "Fine-Tune (Others)",
    rediect: PAGE_ROUTES.finetuning,
    icon: <AutoFixHighIcon sx={{ color: color.primary, fontSize: "16px" }} />
  },
])

export const PRIVATE_REPO_CREDS_FIELDS = Object.freeze(
  {
    "GITHUB_PRIVATE": ["GITHUB_PAT"],
    "GITLAB_PRIVATE": ["GITLAB_PAT"],
    "BITBUCKET_PRIVATE": ["BITBUCKET_APP_PASSWORD"],
    "S3": ["AWS_ACCESS_KEY_ID", "AWS_SECRET_ACCESS_KEY"],
    "AZURE": ["AZURE_ACCOUNT_NAME", "AZURE_ACCOUNT_KEY"],
    "GS": ["GS_CREDS"],
    "GDRIVE": ["GDRIVE_CREDS"]
  }
)

export const ONPREMISE_CONFIG_TYPE_TABS = Object.freeze([
  "GPU Nodes",
  "GPU Types"
])