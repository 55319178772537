import { Grid, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import LoginButton from '../Components/LandingPageComponents/LoginButton';
import '../Styles/App.css';
import { color } from '../Styles/Color';


const LandingView = () => {

    const productLogo = process.env.REACT_APP_PRODUCT_LARGE_LOGO

    return (
        <div className="App">
            <header className="App-header">
                <Grid container>
                    <Grid item xs={7} sx={{ bgcolor: color.primary, alignItems: "center", display: "flex" }}>
                        <Stack spacing={5} p={7} >
                            {productLogo ?
                                <img
                                    src={productLogo}
                                    width="250px"
                                    alt={process.env.REACT_APP_PRODUCT_TYPE}
                                />
                                : <Typography
                                    color={color.white}
                                    fontSize="44px" fontWeight={800}
                                >
                                    {process.env.REACT_APP_PRODUCT_TYPE}
                                </Typography>
                            }
                            <Stack spacing={1}>
                                <Typography fontSize="36px" >
                                    Embracing Open Source.
                                </Typography>
                                <Typography fontSize="32px" >
                                    Our Vision for the Future of AI.
                                </Typography>
                            </Stack>
                            <Typography fontSize="24px">
                                A private and multi-cloud open source LLM Stack
                            </Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={5} container direction="column" className='App-landing'>
                        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Grid container direction="column" className='App-login' gap={3}>
                                <Typography className='App-heading' sx={{
                                    color: '#181818',
                                    fontSize: '2.5rem',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    letterSpacing: '-0.8px',
                                }}>
                                    Get Started!
                                </Typography>
                                <Typography className='App-text' sx={{
                                    color: '#ABABAB',
                                    fontSize: '1.25rem',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '130%',
                                }}>
                                    Sign in with your registered email.
                                </Typography>
                                <LoginButton />
                                <Typography className='App-signup-text' sx={{
                                    color: '#000000',
                                    fontSize: '1.25rem',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '130%',
                                }}>
                                    Don't have an account?
                                    <a href="https://getwaitlist.com/waitlist/12901" className='App-link' style={{
                                        color: '#2E87D9',
                                        fontSize: '1.25rem',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: '130%',
                                        marginLeft: "10px"
                                    }}
                                    >
                                        Sign up!
                                    </a>
                                </Typography>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </header>
        </div>
    );
};

export default LandingView;
