import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material";
import Fuse from 'fuse.js';
import { useEffect, useState } from 'react';
import InferenceList from '../Components/InferenceViewComponents/InferenceList';
import { NewInferenceModal } from '../Components/InferenceViewComponents/NewInferenceModal';
import CustomButton from "../Components/UiComponents/CustomButton";
import Loader from '../Components/UiComponents/Loader';
import { useGetUserInferencesQuery } from '../Services/inferenceApi';
import { color } from '../Styles/Color';

export const InferenceView = () => {

  const [searchedInference, setSearchedInference] = useState("")
  const [searchedData, setSearchedData] = useState([])
  const [isNewInferenceModalOpen, setIsNewInferenceModalOpen] = useState(false)

  const { data: inferenceData, isLoading, isSuccess } =
    useGetUserInferencesQuery(null, { pollingInterval: 10000 });

  useEffect(() => {
    if (isSuccess && inferenceData) {
      const options = {
        keys: ['name'],
        includeScore: true,
      }

      const fuse = new Fuse(inferenceData, options)

      if (searchedInference.length > 0) {
        setSearchedData(
          fuse.search(searchedInference)
            .filter(j => j.score < 0.5)
            .map(j => j.item)
        )
      } else {
        setSearchedData(inferenceData)
      }
    }
  }, [inferenceData, isSuccess, searchedInference])

  if (isLoading) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (

    <Stack spacing={3}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" pb={2}>Inference</Typography>
      <Stack gap={4} direction="row">
        <OutlinedInput
          size='small'
          fullWidth
          border={`1px solid ${color.borders}`}
          sx={{
            fontSize: '15px',
            borderRadius: "8px",
            boxShadow: "0px 0px 4px 0px #00000029"
          }}
          placeholder='Search Inference'
          value={searchedInference}
          onChange={(e) => setSearchedInference(e.target.value)}
          startAdornment={<InputAdornment position="start">
            <SearchIcon fontSize='small' />
          </InputAdornment>}
        />
        <CustomButton height="42px" width="50%" onClick={() => setIsNewInferenceModalOpen(true)}>
          <Stack direction="row" alignItems="center" height="32px" gap={2}>
            <PlayArrowIcon />
            <Typography fontSize="15px">New Inference</Typography>
          </Stack>
        </CustomButton>
      </Stack>
      <InferenceList data={searchedData} />
      <NewInferenceModal isOpen={isNewInferenceModalOpen} handleClose={() => setIsNewInferenceModalOpen(false)} />
    </Stack>
  )
}
