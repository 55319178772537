import AddIcon from '@mui/icons-material/Add';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import ChooseProviderModal from '../Components/CloudProviderComponents/ChooseProviderModal';
import ProviderTable from '../Components/CloudProviderComponents/ProviderTable';
import { ScalegenCloud } from '../Components/CloudProviderComponents/ScalegenCloud';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { openCloudProviderModal } from '../DataStore/modalSlice';
import { useDeleteCloudProviderMutation, useGetUserCloudsQuery } from '../Services/cloudProviderApi';


const CloudProvidersView = () => {
  const dispatch = useDispatch();

  const { data, isLoading } = useGetUserCloudsQuery();
  const [handleDelete, { isLoading: isDeleting }] = useDeleteCloudProviderMutation()

  if (isLoading || isDeleting) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  return (
    <Stack
      spacing={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
      pb={1}
    >
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" >Cloud Providers</Typography>
      <Stack alignItems="end">
        <CustomButton width="30%" onClick={() => dispatch(openCloudProviderModal())}>
          <AddIcon fontSize='small' sx={{ mr: 1 }} />
          Add Cloud Provider
        </CustomButton>
      </Stack>
      <Stack minHeight="33vh">
        <ProviderTable data={data} handleDelete={handleDelete} />
      </Stack>
      <ScalegenCloud />
      <ChooseProviderModal />
    </Stack>
  )
}

export default CloudProvidersView