import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { color } from '../Styles/Color';
import { PAGE_ROUTES } from "./Routes";


export const CONFIGURE_OPTIONS = Object.freeze([
  "Cloud",
  "On-Premise",
])

export const CLOUD_CONFIG_WORKER_OPTIONS = Object.freeze([
  "Minimum Throughput Configuration",
  "Optimal Worker Configuration",
])

export const EMBEDDINGS_CLOUD_CONFIG_WORKER_OPTIONS = Object.freeze([
  "Optimal Worker Configuration",
])

export const SCALING_POLICY_OPTIONS = Object.freeze([
  "Mean Latency Threshold"
])

export const QUANTIZATION_OPTIONS = Object.freeze([
  "fp4",
  "nf4",
  "int8",
])

export const MIXED_PRECISION_OPTIONS = Object.freeze([
  "fp16",
  "bf16"
])

export const TORCH_DTYPE_OPTIONS = Object.freeze([
  "auto",
  "bfloat16",
  "float16",
  "float32"
])

export const NEW_INFERENCE_OPTIONS = Object.freeze([
  {
    name: "New Completions Model Inference",
    rediect: PAGE_ROUTES.newInference,
    icon: <AutoFixHighIcon sx={{ color: color.primary, fontSize: "16px" }} />
  },
  {
    name: "New Embeddings Model Inference",
    rediect: PAGE_ROUTES.newEmbeddingsInference,
    icon: <PlaylistPlayIcon sx={{ color: color.primary, fontSize: "16px" }} />
  },
])
