// /* eslint-disable no-unused-vars */
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import EditIcon from '@mui/icons-material/Edit';
import PhotoFilterIcon from '@mui/icons-material/PhotoFilter';
import { Chip, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router';
import { AutoScalingConfig } from '../Components/InferenceViewComponents/AutoScalingConfig';
import Configure from '../Components/InferenceViewComponents/Configure';
import { DeploymentConfig } from '../Components/InferenceViewComponents/DeploymentConfig';
import { GPUConfig } from '../Components/InferenceViewComponents/GPUConfig';
import { Pricing } from '../Components/InferenceViewComponents/Pricing';
import CustomButton from '../Components/UiComponents/CustomButton';
import Loader from '../Components/UiComponents/Loader';
import { RequiredHeader } from '../Components/UiComponents/RequiredHeader';
import { CLOUD_CONFIG_WORKER_OPTIONS, CONFIGURE_OPTIONS } from '../Configs/JobConstants';
import { PAGE_ROUTES } from '../Configs/Routes';
import { useGetArtifactStoragePathsQuery } from '../Services/artifactStorageApi';
import { useGetGpuTypesMutation, useGetInstanceTypesMutation, useGetUserCloudsQuery } from '../Services/cloudProviderApi';
import { useCreateInferenceMutation } from '../Services/inferenceApi';
import { useGetUserOnPremNodesQuery } from '../Services/nodeApi';
import { color } from '../Styles/Color';

const initialValue = {
  "name": "",
  "model": "",
  "base_model": null,
  "inf_type": "llm",
  "hf_token": "",
  "allow_spot_instances": false,
  "logs_store": "",
  "cloud_providers": [],
  "initial_worker_config": {
    "min_workers": 0,
    "initial_workers_gpu": null,
    "initial_workers_gpu_num": null,
    "use_other_gpus": true,
    "instance_types": [],
    "use_on_prem": false,
    "use_cloudburst": false,
    "on_prem_node_ids": []
  },
  "autoscaling_config": {
    "enable_fast_autoscaling": false,
    "enable_speedup_shared": false,
    "lower_allowed_latency_sec": 0.2,
    "scale_down_time_window_sec": 300,
    "scale_to_zero": false,
    "scale_to_zero_timeout_sec": 1800,
    "scale_up_time_window_sec": 300,
    "scaling_down_timeout_sec": 1200,
    "scaling_up_timeout_sec": 1200,
    "upper_allowed_latency_sec": 1
  },
  "max_price_per_hour": 0,
  "min_throughput_rate": null,
  "controller_cloud_config": {
    "public_url": true,
    "use_ssl": true,
    "use_api_gateway": true,
    "vpc_id": null,
    "cloud_provider": "SCALEGENAI",
    "region": "",
    "api_gateway_data": null
  },
  "controller_on_prem_config": {
    use_ssl: true,
    on_prem_node_id: ""
  }
}


export const CreateNewInferenceView = () => {
  const isEmbeddings = useLocation().pathname.includes('embeddings') ? true : false;

  const nav = useNavigate()

  const { register, formState: { errors }, watch, handleSubmit, setValue } =
    useForm({
      defaultValues: {
        ...initialValue,
        "inf_type": isEmbeddings ? "embedding" : "llm",
      }
    })

  const [cloudProvider, setCloudProvider] = useState([])
  const [region, setRegion] = useState([])
  const [submit, { isLoading: isSubmitting, isSuccess }] = useCreateInferenceMutation()
  const { data: artifactStoragePaths, isLoading: isFetchingPaths, isSuccess: isStoragesFetched } = useGetArtifactStoragePathsQuery()
  const { data: gpuOptions, isLoading } = useGetUserOnPremNodesQuery()

  const { data: cloudProviderOptions, isLoading: isFetchingClouds } = useGetUserCloudsQuery()
  const [getGPUTypes, { data: gpuTypeOptions, isLoading: isFetchingGPUs }] = useGetGpuTypesMutation()
  const [getInstanceTypes, { data: instanceOptions, isLoading: isFetchingInstances }] = useGetInstanceTypesMutation()

  const [regionOptions, setRegionOptions] = useState([])
  const [cloudGpuWorkerConfig, setCloudGPUWorkerConfig] = useState(
    !isEmbeddings && CLOUD_CONFIG_WORKER_OPTIONS[0]
  )


  useEffect(() => {
    if (cloudGpuWorkerConfig === CLOUD_CONFIG_WORKER_OPTIONS[0]) {
      setValue(
        "initial_worker_config",
        {
          "min_workers": "0",
          "initial_workers_gpu": null,
          "initial_workers_gpu_num": null,
          "use_other_gpus": true,
          "instance_types": [],
          "use_on_prem": false,
          "use_cloudburst": false,
          "on_prem_node_ids": []
        }
      )
      setValue("autoscaling_config.scale_to_zero", false)
    } else {
      setValue("min_throughput_rate", null)
      setValue("autoscaling_config.scale_to_zero", true)
    }
  }, [cloudGpuWorkerConfig, setValue])

  useEffect(() => {
    if (isStoragesFetched && artifactStoragePaths?.length > 0) {
      setValue("logs_store", artifactStoragePaths[0]?.name)
    }
  }, [isStoragesFetched, artifactStoragePaths, setValue])

  useEffect(() => {
    if (cloudProviderOptions && cloudProviderOptions?.length > 0) {
      setCloudProvider(
        [...cloudProviderOptions
          ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
          ?.map(cp => cp.cloud_provider), "SCALEGENAI"] || []
      )
      setRegionOptions(
        [...cloudProviderOptions
          ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
          .reduce(
            (accumulator, cp) => {
              return [...accumulator, ...cp.regions.map(region => `${cp.cloud_provider}:${region}`)]
            },
            [],
          ),
          "SCALEGENAI:US", "SCALEGENAI:ASIA", "SCALEGENAI:EU", "SCALEGENAI:CANADA"
        ]
      )
      setRegion(
        [...cloudProviderOptions
          ?.filter(d => ["AWS", "AZURE", "GCP"].includes(d.cloud_provider))
          .reduce(
            (accumulator, cp) => {
              return [...accumulator, ...cp.regions.map(region => `${cp.cloud_provider}:${region}`)]
            },
            [],
          ),
          "SCALEGENAI:US", "SCALEGENAI:ASIA", "SCALEGENAI:EU", "SCALEGENAI:CANADA"
        ]
      )
    }
  }, [cloudProviderOptions])


  useEffect(() => {
    const timer = setTimeout(() => {
      if (cloudProvider.length > 0 && region.length > 0) {
        getGPUTypes({
          cloudProviders: cloudProvider,
          cloudRegions: region
        })
        getInstanceTypes({
          cloudProviders: cloudProvider,
          cloudRegions: region
        })
      }
    }, 3000)
    setValue(
      'cloud_providers',
      cloudProvider.map(
        cp => {
          return {
            name: cp,
            regions: region?.filter(r => r.includes(cp)).map(r => r.split(":")[1])
          }
        }
      )
    )

    return () => {
      clearTimeout(timer)
    }
  }, [cloudProvider, getGPUTypes, getInstanceTypes, region, setValue])

  const handleValidation = () => {
    handleSubmit(handleStart)()
  }

  const handleStart = (data) => {
    if (!isEmbeddings && cloudGpuWorkerConfig === CLOUD_CONFIG_WORKER_OPTIONS[0]) {
      data = {
        ...data,
        initial_worker_config: null
      }
    }
    if (selected === CONFIGURE_OPTIONS[0]) {
      data.controller_on_prem_config = null
    }
    if (selected === CONFIGURE_OPTIONS[1]) {
      data.controller_cloud_config = null
    }
    submit(data)
  }

  const [selected, setSelected] = useState(CONFIGURE_OPTIONS[0])

  if (isSubmitting || isFetchingPaths || isLoading || isFetchingClouds) {
    return <Stack height="70vh">
      <Loader />
    </Stack>
  }

  if (isSuccess) {
    nav(PAGE_ROUTES.inferences)
  }

  return (
    <Stack gap={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
    >
      <Typography fontFamily="IBMPlexSansSemiBold" fontSize="25px" pb={2}>
        New Inference
      </Typography>
      <Stack direction="row" gap={2} alignItems="center">
        <RequiredHeader>
          Deployment Name
        </RequiredHeader>
        <TextField
          size='small'
          variant="standard"
          placeholder='Enter deployment name'
          sx={{
            fontFamily: "IBMPlexSansSemiBold",
          }}
          InputProps={{
            style: {
              fontFamily: "IBMPlexSansSemiBold", fontSize: "15px",
              color: color.primary
            },
          }}
          {...register("name", { required: { value: true, message: "This field is required" } })}
          error={errors?.name ? true : false}
          helperText={errors?.name?.message}
        />
        <EditIcon sx={{ color: color.primary, fontSize: "16px" }} />
      </Stack>
      <Typography fontSize="17px" mt={2}>
        {`${isEmbeddings ? "Embeddings" : "Completions"} Model Configurations`}
      </Typography>
      <Configure
        infType={isEmbeddings ? "embedding" : "llm"}
        register={register} setValue={setValue} watch={watch} errors={errors}
      />
      <DeploymentConfig
        watch={watch} setValue={setValue}
        userClouds={
          [...cloudProviderOptions, { cloud_provider: "SCALEGENAI", regions: ["US"] }]
          || []}
        infType={isEmbeddings ? "embedding" : "llm"}
        register={register}
        onPremNodes={gpuOptions}
        selected={selected} setSelected={setSelected}
      />
      <GPUConfig
        gpuOptions={gpuOptions}
        instanceOptions={instanceOptions}
        cloudProviderOptions={cloudProviderOptions || []}
        regionOptions={regionOptions}
        gpuTypeOptions={gpuTypeOptions}
        cloudProvider={cloudProvider} setCloudProvider={setCloudProvider}
        region={region} setRegion={setRegion}
        cloudGpuWorkerConfig={cloudGpuWorkerConfig} setCloudGPUWorkerConfig={setCloudGPUWorkerConfig}
        register={register} setValue={setValue} watch={watch} infType={isEmbeddings ? "embedding" : "llm"}
        isFetchingGPUs={isFetchingGPUs} isFetchingInstances={isFetchingInstances}
      />
      <AutoScalingConfig
        register={register} watch={watch} setValue={setValue}
        cloudGpuWorkerConfig={cloudGpuWorkerConfig}
      />
      <Stack py={2} px={3} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} >
        <Chip
          sx={
            {
              bgcolor: "#FFFFFF",
              color: color.primary,
              width: "25%",
              borderRadius: "6px",
              fontSize: "15px"
            }
          }
          label={
            <Stack gap={1} direction="row" alignItems="center">
              <PhotoFilterIcon sx={{ color: color.primary, fontSize: "16px" }} />
              Results
            </Stack>
          } />
        <Typography fontWeight="600" fontSize="15px">
          Results Folder
        </Typography>
        <Select
          displayEmpty
          size='small'
          sx={{ fontSize: '15px', width: "70%", borderRadius: '8px', background: '#fff' }}
          value={watch("logs_store")}
          onChange={(e) => setValue("logs_store", e.target.value)}
        >
          <MenuItem value="" disabled sx={{ fontSize: "15px" }}>
            Choose Results Folder
          </MenuItem>
          {
            artifactStoragePaths?.map((path, idx) => (
              <MenuItem value={path.name} key={idx} sx={{ fontSize: "15px" }}>
                {path?.name}
              </MenuItem>
            ))
          }
        </Select>
      </Stack >
      <Pricing
        // price={price} setPrice={setPrice} 
        register={register} watch={watch}
      />
      <Stack alignItems="end" >
        <CustomButton width="15%" onClick={handleValidation}>
          <Stack fontSize="15px" direction="row" alignItems="center">
            Start
            <ArrowForwardIosIcon sx={{ fontSize: "15px" }} />
          </Stack>
        </CustomButton>
      </Stack >
    </Stack >
  )
}
