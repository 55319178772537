import { Stack, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { getStatus } from '../../Configs/GPUMachinesConstants';
import { PAGE_ROUTES } from '../../Configs/Routes';
import { color } from '../../Styles/Color';

function createData(gpu_type, gpu_count, region, id, status, timestamp, cost) {
  timestamp = timestamp.split(".")[0]
  cost = Math.round(cost * 1000) / 1000
  return { gpu_type, gpu_count, region, id, status, timestamp, cost };
}

export function GPUMachinesList({ data }) {

  const rows = (data || []).map((d) =>
    createData(d.instance_details.gpu_type, d.instance_details.gpu_count, d.instance_details.region, d.id, d.status, d.timestamp, d.instance_details.on_demand));

  const nav = useNavigate()

  return (
    <TableContainer component={Paper}
      sx={{
        boxShadow: "0px 0px 4px 0px #00000029",
        maxHeight: "75vh",
      }}>
      <Table sx={{ minWidth: 650 }} stickyHeader>
        <TableHead>
          <TableRow >
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Id</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="25%">
              GPU Type x GPU Count</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="12%">Region</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Price/Hr</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="18%">Created</TableCell>
            <TableCell sx={{ backgroundColor: color.secondaryBackground }} width="15%">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            rows.length > 0 ?
              rows.map((row, idx) => (
                <TableRow
                  key={idx}
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    '&:hover': {
                      cursor: 'pointer',
                    },
                  }}
                  hover
                  onClick={() => nav(PAGE_ROUTES.machines + "/" + row.id)}
                >
                  <TableCell component="th" scope="row" sx={{ fontSize: "13px" }}>
                    {row.id}
                  </TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.gpu_type} x {row.gpu_count}</TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.region}</TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>$ {row.cost}</TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{row.timestamp}</TableCell>
                  <TableCell sx={{ fontSize: "13px" }}>{getStatus(row.status)}</TableCell>
                </TableRow>
              )) :
              <Stack height="10vh" justifyContent="center" px={2}>
                <Typography color={color.primary} fontSize="15px">No data available</Typography>
              </Stack>
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
}