import BrokenImageIcon from '@mui/icons-material/BrokenImage'
import { Chip, FormControlLabel, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { CLOUD_CONFIG_WORKER_OPTIONS, SCALING_POLICY_OPTIONS } from '../../Configs/JobConstants'
import { color } from '../../Styles/Color'
import BoxSwitch from '../UiComponents/BoxSwitch'
import InputField from '../UiComponents/InputField'


export const AutoScalingConfig = ({ watch, register, setValue, cloudGpuWorkerConfig }) => {

  // eslint-disable-next-line no-unused-vars
  const [scalingPolicy, setScalingPolicy] = useState(SCALING_POLICY_OPTIONS[0])

  return (
    <Stack py={2} px={3} spacing={3} bgcolor={color.secondaryBackground} borderRadius="8px"
      border={`1px solid ${color.borders}`} >
      <Chip
        sx={
          {
            bgcolor: "#FFFFFF",
            color: color.primary,
            width: "25%",
            borderRadius: "6px",
            fontSize: "15px"
          }
        }
        label={
          <Stack gap={1} direction="row" alignItems="center">
            <BrokenImageIcon sx={{ color: color.primary, fontSize: "16px" }} />
            Auto-Scaling
          </Stack>
        } />
      {/* <InputField state={scalingPolicy} setState={() => { }} width="70%" /> */}
      <Stack direction="row" gap={2} alignItems="center">
        <Typography fontSize="16px">Auto-Scaling Policy</Typography>
        <Typography fontSize="16px">-</Typography>
        <Typography color={color.primary} fontSize="15px">
          {scalingPolicy}
        </Typography>
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="95%">
        <Stack direction="row" gap={2} width="50%" alignItems="center">
          <Typography width="30%">Latency</Typography>
          <InputField
            // state={latency} setState={(e) => setLatency(e.target.value)}
            register={register} field="autoscaling_config.upper_allowed_latency_sec"
            watch={watch}
            width="40%" />
          <Typography >sec</Typography>
        </Stack>
        {/* <Stack direction="row" gap={2} width="50%" alignItems="center">
          <Typography >Lookback Window (secs)</Typography>
          <InputField
            //state={lookupTimeframe}
            // setState={(e) => setLookupTimeframe(e.target.value)} 
            register={register} field="autoscaling_config.time_window_sec"
            width="40%" />
        </Stack> */}
      </Stack>
      <Stack direction="row" justifyContent="space-between" width="95%">
        <Stack direction="row" gap={2} width="50%" alignItems="center">
          <Typography >Scale-up Time Window (secs)</Typography>
          <InputField
            // state={lookupTimeframe}
            //   setState={(e) => setLookupTimeframe(e.target.value)} 
            register={register} field="autoscaling_config.scale_up_time_window_sec"
            watch={watch}
            width="40%" />
        </Stack>
        <Stack direction="row" gap={2} width="50%" alignItems="center">
          <Typography >Scale-down Time Window (secs)</Typography>
          <InputField
            // state={lookupTimeframe}
            //   setState={(e) => setLookupTimeframe(e.target.value)} 
            register={register} field="autoscaling_config.scale_down_time_window_sec"
            watch={watch}
            width="40%" />
        </Stack>
      </Stack>
      {
        <Stack spacing={2} >
          <FormControlLabel
            value={watch("autoscaling_config.scale_to_zero")}
            control={
              <BoxSwitch
                onChange={(e) => setValue("autoscaling_config.scale_to_zero", e.target.checked)}
              />
            }
            disabled={cloudGpuWorkerConfig !== CLOUD_CONFIG_WORKER_OPTIONS[0]}
            label={<Stack direction="row" alignItems="center">
              <Typography fontSize="17px" mx={1}>Scale to zero</Typography>
            </Stack >}
          />
          {
            watch("autoscaling_config.scale_to_zero") &&
            <Stack direction="row" gap={2} width="50%" alignItems="center">
              <Typography >Scale to zero Window (sec)</Typography>
              <InputField
                // state={lookupTimeframe}
                //   setState={(e) => setLookupTimeframe(e.target.value)} 
                register={register} field="autoscaling_config.scale_to_zero_timeout_sec"
                watch={watch}
                width="40%" />
            </Stack>
          }
        </Stack>
      }
      <FormControlLabel sx={{ py: 1 }}
        value={watch("autoscaling_config.enable_fast_autoscaling")}
        control={
          <BoxSwitch
            onChange={(e) => setValue("autoscaling_config.enable_fast_autoscaling", e.target.checked)}
          />
        }
        label={<Stack direction="row" alignItems="center">
          <Typography fontSize="17px" mx={1}>Enable Fast Autoscaling</Typography>
        </Stack >}
      />
    </Stack >
  )
}
