import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { Alert, Chip, IconButton, Input, InputAdornment, Stack, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import DeleteConfirmationModal from '../Components/DeleteConfirmation/DeleteConfirmationModal'
import { getStatus } from '../Components/InferenceViewComponents/InferenceList'
import CustomButton from '../Components/UiComponents/CustomButton'
import Loader from '../Components/UiComponents/Loader'
import { useDeleteLLMGatewayMutation, useGetLLMGatewayQuery } from '../Services/llmgatewayApi'
import { useGetSecretQuery } from '../Services/secretsApi'
import { color } from '../Styles/Color'
import { NotFoundErrorPage } from './NotFoundErrorPage'


export const LLMGatewayDetailsView = () => {

  const location = useLocation()
  const { data: LLMGatewayDetails, isLoading: isLoadingDetails } =
    useGetLLMGatewayQuery(location.pathname.split('/').pop(), {
      skip: false,
    })

  const { data: secret, isLoading: isLoadingSecret } =
    useGetSecretQuery("AUTH_ENDPOINT_KEY_" + location.pathname.split('/').pop())

  const [deleteGateway, { isLoading: isDeleting }] = useDeleteLLMGatewayMutation()

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [isCopied, setIsCopied] = useState(false)

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = (text) => {
    copyTextToClipboard(text)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
  }


  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

  useEffect(() => {
    if (isConfirmedDelete) {
      deleteGateway(LLMGatewayDetails?.id)
    }
  }, [LLMGatewayDetails?.id, deleteGateway, isConfirmedDelete])

  const handleDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  if (isLoadingDetails || isLoadingSecret || isDeleting) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return LLMGatewayDetails ? (
    <Stack
      spacing={2}
      overflow="auto"
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        }
      }}
      py={1}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Typography fontSize="16px">Gateway Name</Typography>
        <Typography color={color.primary} fontSize="16px">{LLMGatewayDetails?.name}</Typography>
      </Stack>
      <Stack direction="row" gap={2}>
        <Stack p={1} pb={2} spacing={2} width="70%"
          bgcolor={color.secondaryBackground} borderRadius="8px"
          border={`1px solid ${color.borders}`}
        >
          <Stack width="15%">
            {getStatus(LLMGatewayDetails?.status)}
          </Stack>
          <Stack px={3} spacing={2}>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">Inference Deployment ID</Typography>
              <Typography color={color.primary} fontSize="15px">
                {(LLMGatewayDetails).id}
              </Typography>
              <Tooltip title={isCopied ? "Copied" : "Copy"}>
                <ContentCopyIcon
                  sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                  onClick={() => handleCopyClick((LLMGatewayDetails).id)}
                />
              </Tooltip>
            </Stack>
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">LLM Providers</Typography>
              <Stack direction="row" gap={1} alignItems="center">
                {
                  (LLMGatewayDetails).llm_apis.map(provider =>
                    <Chip
                      sx={{
                        bgcolor: color.lightBlue,
                        color: color.primary,
                        borderRadius: "20px",
                        px: 1
                      }}
                      label={
                        <Stack direction="row" alignItems="center" gap={1} fontSize="12px">
                          {provider}
                        </Stack>
                      }
                    />
                  )
                }
              </Stack>
            </Stack>
            {
              (LLMGatewayDetails).link &&
              <Stack direction="row" gap={2} alignItems="center">
                <Typography fontSize="15px">Gateway Link</Typography>
                <Typography color={color.primary} fontSize="15px">
                  {(LLMGatewayDetails).link}
                </Typography>
                <Tooltip title={isCopied ? "Copied" : "Copy"}>
                  <ContentCopyIcon
                    sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                    onClick={() =>
                      handleCopyClick((LLMGatewayDetails).link)
                    }
                  />
                </Tooltip>
              </Stack >
            }
            <Stack direction="row" gap={2} alignItems="center">
              <Typography fontSize="15px">API key</Typography>
              {
                secret ?
                  <Stack direction="row" gap={1} minWidth="40%" alignItems="center">
                    <Input
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ?
                              <VisibilityOff
                                sx={{ fontSize: "24px" }}
                              /> :
                              <Visibility
                                sx={{ fontSize: "24px" }}
                              />}
                          </IconButton>
                        </InputAdornment>
                      }
                      value={secret?.secret["AUTH_ENDPOINT_KEY_" + (LLMGatewayDetails).id]}
                      onChange={() => { }}
                      sx={{
                        fontSize: "15px",
                        width: "100%",
                        color: color.primary,
                      }}
                      disableUnderline
                    />
                    <Tooltip title={isCopied ? "Copied" : "Copy"}>
                      <ContentCopyIcon
                        sx={{ fontSize: "18px", "&:hover": { cursor: "pointer" } }}
                        onClick={() => handleCopyClick(
                          secret?.secret["AUTH_ENDPOINT_KEY_" + (LLMGatewayDetails).id]
                        )}
                      />
                    </Tooltip>
                  </Stack>
                  :
                  <Typography fontSize="15px">( not available )</Typography>
              }
            </Stack >
            {
              (LLMGatewayDetails).link &&
              <Stack direction="row" gap={2} alignItems="center">
                <Typography fontSize="15px">Metrics Dashboard</Typography>
                <Link to={(LLMGatewayDetails).link + "/metrics"} target="_blank">
                  <Typography color={color.primary} fontSize="15px">
                    {(LLMGatewayDetails).link + "/metrics"}
                  </Typography>
                </Link>
              </Stack >
            }
            {
              (LLMGatewayDetails).link &&
              <Stack direction="row" gap={2} alignItems="center">
                <Typography fontSize="15px">Metrics Dashboard</Typography>
                <Link to={(LLMGatewayDetails).link + "/metrics"} target="_blank">
                  <Typography color={color.primary} fontSize="15px">
                    {(LLMGatewayDetails).link + "/prompts"}
                  </Typography>
                </Link>
              </Stack >
            }
            <Alert severity="info" square={false} sx={{ width: "80%", fontSize: "14px" }}>
              For Metrics and Prompt dashboards login with Inference Deployment ID as username and API key as password.
            </Alert>
          </Stack >
          <Stack direction="row" justifyContent="end" gap={1} width="95%">
            {
              !((LLMGatewayDetails).status === "DELETED" || (LLMGatewayDetails).status === "DELETING") &&
              <CustomButton width="20%"
                onClick={() =>
                  setIsDeleteModalOpen(true)
                }
                color="#FF6B6A"
              >
                Delete
              </CustomButton>
            }
            {
              (LLMGatewayDetails).status === "INACTIVE" &&
              <CustomButton width="20%"
                onClick={() => { }}
                color="#FF6B6A"
              >
                Start Gateway
              </CustomButton>
            }
          </Stack>
        </Stack >
        <Stack py={2} px={3} gap={2} bgcolor={color.secondaryBackground} borderRadius="8px" border={`1px solid ${color.borders}`} width="50%">
          <Chip
            sx={
              {
                bgcolor: "#FFFFFF",
                color: color.primary,
                width: "50%",
                borderRadius: "6px",
                fontSize: "15px"
              }
            }
            label={
              <Stack gap={1} direction="row" alignItems="center">
                Gateway Settings
              </Stack>
            } />

          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Semantic Caching</Typography>
            <Typography color={color.primary} fontSize="15px">
              {(LLMGatewayDetails)?.has_semantic_caching === true ? "Enabled" : "Disabled"}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">PII Anonymization</Typography>
            <Typography color={color.primary} fontSize="15px">
              {(LLMGatewayDetails)?.has_pii_anon === true ? "Enabled" : "Disabled"}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Prompt injection checks</Typography>
            <Typography color={color.primary} fontSize="15px">
              {(LLMGatewayDetails)?.has_prompt_inject_check === true ? "Enabled" : "Disabled"}
            </Typography>
          </Stack>
          <Stack direction="row" gap={2} alignItems="center">
            <Typography fontSize="15px">Number of Retries</Typography>
            <Typography color={color.primary} fontSize="15px">
              {(LLMGatewayDetails)?.num_reties || 0}
            </Typography>
          </Stack>
        </Stack >
      </Stack>
      <DeleteConfirmationModal
        handleDelete={handleDelete} itemName="LLM Gateway"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </Stack >
  ) : (
    <NotFoundErrorPage />
  )
}
