import {
  Box, Chip,
  FormControlLabel,
  MenuItem, OutlinedInput, Select, Stack, Typography
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CLOUD_PROVIDERS_OPTIONS, CLOUD_PROVIDERS_OPTIONS_CREDENTIALS } from '../../Configs/CloudProviderConstants'
import { AWS } from '../../Configs/Constants'
import { useGetCloudProviderRegionsQuery } from '../../Services/cloudProviderApi'
import { color } from '../../Styles/Color'
import BoxSwitch from '../UiComponents/BoxSwitch'
import InputField from '../UiComponents/InputField'
import Loader from '../UiComponents/Loader'

const ProviderCredtials = ({
  formValues,
  setFormValues,
}) => {
  const { name, icon } = CLOUD_PROVIDERS_OPTIONS[formValues.cloud_provider]

  const { data: regions, isLoading } = useGetCloudProviderRegionsQuery(name.toUpperCase());

  const [isCustomVPC, setIsCustomVPC] = useState(false)

  useEffect(() => {
    Object.keys(formValues.creds)
      .filter(key => key.includes("VPC"))
      .filter(key =>
        !formValues.regions.includes(key.split("_")[1])
      )
      .forEach(key => delete formValues.creds[key])

    if (isCustomVPC) {
      formValues.regions.forEach(region => {
        if (!formValues.creds[`AWS_${region}_VPC_ID`]) {
          formValues.creds[`AWS_${region}_VPC_ID`] = ""
        }
      })
    } else {
      Object.keys(formValues.creds)
        .filter(key => key.includes("VPC"))
        .forEach(key => delete formValues.creds[key])
    }
  }, [formValues.creds, formValues.regions, isCustomVPC])

  if (isLoading) {
    return <Stack height="45vh">
      <Loader />
    </Stack>
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setFormValues({ ...formValues, regions: value })
  };

  return (
    <Stack spacing={3} >
      <Stack spacing={1}>
        <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold" >
          Provider
        </Typography>
        <Stack direction="row" gap={1.5} alignItems="center">
          <Stack
            width="40px"
            height="40px"
            justifyContent="center"
            alignItems="center"
            border="2px solid"
            borderColor={color.primary}
            borderRadius="8px"
            boxShadow="0px 0px 1px 2px #92CBFF"
            sx={{
              cursor: "pointer",
            }}
          >
            {icon("20px")}
          </Stack>
          <Typography
            fontFamily="IBMPlexSansSemiBold"
            fontSize="15px"
          >
            {name}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1}>
        <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold" >
          Regions
        </Typography>
        <Select
          size='small'
          multiple
          value={formValues.regions}
          onChange={handleChange}
          displayEmpty
          input={<OutlinedInput />}
          renderValue={(selected, idx) => (
            <Box key={idx} sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {
                selected.length === 0 ?
                  <Typography fontSize="15px">Choose Regions</Typography>
                  : selected.map((value) => (
                    <Chip key={value} sx={{
                      bgcolor: color.lightBlue,
                      borderRadius: "6px",
                      fontSize: "12px",
                    }} label={value} />
                  ))}
            </Box>
          )}
          sx={{ bgcolor: color.white, width: "70%", borderRadius: "8px" }}
        >
          {regions.map((name, idx) => (
            <MenuItem
              key={idx}
              value={name}
              sx={{ fontSize: "15px" }}
            >
              {name}
            </MenuItem>
          ))}
        </Select>

        <Typography fontFamily="IBMPlexMonoLightItalic" fontSize="10px" color="#ABABAB">
          All regions will be selected it the field is empty.
        </Typography>
      </Stack>
      <Stack borderRadius="8px" border="1px solid #ABABAB" spacing={1}>
        <Stack p={1} px={2} bgcolor={color.lightBlue}
          sx={{
            borderTopRightRadius: "8px",
            borderTopLeftRadius: '8px'
          }}
        >
          <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold">Provider Credentials</Typography>
        </Stack>
        <Stack py={2.5} px={2} spacing={2}>
          {
            CLOUD_PROVIDERS_OPTIONS_CREDENTIALS[formValues.cloud_provider]?.map((creds, idx) => {
              let key = creds.formKey;
              return <Stack key={idx} spacing={1}>
                <Typography fontSize="1rem" fontFamily="IBMPlexSansSemiBold">{creds.label}</Typography>
                <InputField
                  state={formValues.creds[key]}
                  setState={(e) => setFormValues(
                    {
                      ...formValues,
                      creds: {
                        ...formValues.creds,
                        [key]: e.target.value
                      }
                    })}
                  placeholder={creds.placeholder}
                />
              </Stack>
            })
          }
        </Stack>
      </Stack>
      <Stack>
        {
          formValues.cloud_provider === AWS &&
          <FormControlLabel sx={{ p: 2 }}
            value={isCustomVPC}
            control={
              <BoxSwitch
                disabled={formValues.cloud_provider !== AWS || formValues.regions.length === 0}
                onChange={(e) => setIsCustomVPC(e.target.checked)}
              />
            }
            label={<Typography fontSize="17px">Custom VPCs</Typography>}
          />
        }
        {
          isCustomVPC === true &&
          <Stack py={2} px={2} gap={1.5} bgcolor={color.secondaryBackground} borderRadius="8px"
            border={`1px solid ${color.borders}`} >
            <Stack direction="row" alignItems="center" fontSize="15px" gap={2}>
              <Stack width="40%" gap={1}>
                <Typography fontSize="15px">
                  Region
                </Typography>

              </Stack>
              <Stack width="40%" gap={1}>
                <Typography fontSize="15px">
                  VPC ID
                </Typography>

              </Stack>
            </Stack>
            {
              formValues.regions.map((region, idx) =>
                <Stack direction="row" key={idx} alignItems="center" fontSize="15px" gap={2}>
                  <InputField
                    width="40%"
                    disabled={true}
                    state={region}
                  />
                  <InputField
                    width="40%"
                    placeholder="Enter VPC ID"
                    state={formValues.creds[`AWS_${region}_VPC_ID`]}
                    setState={(e) => setFormValues(
                      {
                        ...formValues,
                        creds: {
                          ...formValues.creds,
                          [`AWS_${region}_VPC_ID`]: e.target.value
                        }
                      })}
                  />
                </Stack>
              )
            }
          </Stack >
        }
        {/* {
            formValues.provider !== AZURE && <FormControlLabel
              control={
                <BoxSwitch
                  disabled={formValues.provider === AZURE}
                  onChange={(e) => setFormValues({ ...formValues, useScratchPad: e.target.checked })}
                />
              }
              label={<Typography fontSize="17px">Use Scratchpad Storage</Typography>}
            />
          }  */}
      </Stack>
    </Stack >
  )
}

export default ProviderCredtials