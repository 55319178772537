import { Button } from '@mui/material'
import React from 'react'
import { color } from '../../Styles/Color'

const CustomButton = ({ children, onClick, width, background, height, disabled }) => {
  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      sx={{
        bgcolor: `${background || color.primary}`,
        color: '#E4E4E4',
        px: 3,
        borderRadius: "8px",
        fontSize: "15px",
        "&:hover": {
          bgcolor: `${background || color.primary}`
        },
        "&:disabled": {
          bgcolor: "#92CBFF",
          color: '#fff'
        },
        height: `${height ? height : "20px"}`,
        width: `${width ? width : "100%"}`,
        py: 2,
        boxShadow: "0px 0px 4px 0px #0000001F",
        border: `1px solid ${color.borders}`,
      }} >
      {children}
    </Button>
  )
}

export default CustomButton