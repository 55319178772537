import { Stack, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import DeleteConfirmationModal from '../Components/DeleteConfirmation/DeleteConfirmationModal'
import { Certificate } from '../Components/InferenceViewComponents/Certificate'
import { InferenceDetails } from '../Components/InferenceViewComponents/InferenceDetails'
import Loader from '../Components/UiComponents/Loader'
import NavigationTabs from '../Components/UiComponents/NavigationTabs'
import { useDeleteInferenceMutation, useGetInferenceQuery } from '../Services/inferenceApi'
import { color } from '../Styles/Color'
import { NotFoundErrorPage } from './NotFoundErrorPage'


export const InferenceDetailView = () => {

  const location = useLocation()

  const { data: inferenceDetails, isLoading: isLoadingDetails, isSuccess } =
    useGetInferenceQuery(location.pathname.split('/').pop(), {
      skip: false,
    })

  const [editableConfig, setEditableConfig] = useState({
    "allow_spot_instances": false,
    "max_price_per_hour": 0,
  })


  useEffect(() => {
    if (isSuccess && inferenceDetails) {
      setEditableConfig({
        "allow_spot_instances": inferenceDetails?.allow_spot_instances,
        "max_price_per_hour": inferenceDetails?.max_price_per_hour || 0,
        ...((inferenceDetails?.initial_worker_config !== null
          && inferenceDetails?.initial_worker_config?.min_workers !== null) &&
          { "min_workers": inferenceDetails?.initial_worker_config?.min_workers || 0 }),
        ...(inferenceDetails?.min_throughput_rate !== null &&
          { "min_throughput_rate": inferenceDetails?.min_throughput_rate || 0 }),
      })
    }
  }, [isSuccess, inferenceDetails])


  const [deleteInference, { isLoading }] = useDeleteInferenceMutation()

  const [isConfirmedDelete, setIsConfirmedDelete] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [detailsTab, setDetailsTab] = useState("0")

  useEffect(() => {
    if (isConfirmedDelete) {
      deleteInference(inferenceDetails?.id)
    }
  }, [deleteInference, inferenceDetails?.id, isConfirmedDelete])

  const handleDelete = () => {
    setIsConfirmedDelete(true)
    setIsDeleteModalOpen(false)
  }

  const DETAILS_PANELS = (
    (
      inferenceDetails?.controller_cloud_config &&
      inferenceDetails?.controller_cloud_config.use_api_gateway === false
      && inferenceDetails?.controller_cloud_config.use_ssl === true
    )
    ||
    (
      inferenceDetails?.controller_on_prem_config
      && inferenceDetails?.controller_on_prem_config.use_ssl === true
    )
  ) ? [
    <InferenceDetails
      inferenceDetails={inferenceDetails} editableConfig={editableConfig}
      setEditableConfig={setEditableConfig} setIsDeleteModalOpen={setIsDeleteModalOpen}
    />,
    <Certificate
      id={location.pathname.split('/').pop()} logs_store={inferenceDetails?.logs_store}
      type={inferenceDetails?.inf_type} controller_ip={inferenceDetails?.controller_ip || "0.0.0.0"}
    />
  ] : [
    <InferenceDetails
      inferenceDetails={inferenceDetails} editableConfig={editableConfig}
      setEditableConfig={setEditableConfig} setIsDeleteModalOpen={setIsDeleteModalOpen}
    />
  ]


  const DETAILS_TAB = (
    (
      inferenceDetails?.controller_cloud_config &&
      inferenceDetails?.controller_cloud_config.use_api_gateway === false
      && inferenceDetails?.controller_cloud_config.use_ssl === true
    )
    ||
    (
      inferenceDetails?.controller_on_prem_config
      && inferenceDetails?.controller_on_prem_config.use_ssl === true
    )
  ) ? [
    "Configuration",
    "Certificate",
  ] : [
    "Configuration",
  ]


  if (isLoadingDetails || isLoading) {
    return <Stack height="80vh">
      <Loader />
    </Stack>
  }

  return inferenceDetails ? (
    <Stack
      spacing={2}
      py={1}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Typography fontSize="16px">Deployment Name</Typography>
        <Typography color={color.primary} fontSize="16px">{inferenceDetails?.name}</Typography>
      </Stack>
      <NavigationTabs tabs={DETAILS_TAB} panels={DETAILS_PANELS} value={detailsTab}
        setValue={setDetailsTab} />
      <DeleteConfirmationModal
        handleDelete={handleDelete} itemName="Inference Deployement"
        open={isDeleteModalOpen} setOpen={setIsDeleteModalOpen}
      />
    </Stack >
  ) : (
    <NotFoundErrorPage />
  )
}
